/**
 * module: RemoveForm.js
 * author: Rob Blanch
 * usage: rendering logic for Eigengrau Admin remove account form
 * license: see https://www.stonylanetech.com/licenses/Eigengrau/license.txt for details
 * 
 * (C) 2024 Stony Lane Tech LLC
 */
import React from 'react';
import {withRouter} from 'react-router';
import SLTHeader from './SLTHeader.js';
import FormMessage from './FormMessage.js';

const SLTRtrQryParamHelper = require('./SLTRtrQryParamHelper.js');
const SLTEigengrauAppErrCodes = require('./SLTEigengrauAppErrCodes.js').SLTEigengrauAppErrCodes;

/**
 * class: RemoveForm
 * usage: renders the remove Eigengrau account form
 * props:
 *      logo - image to show in header of form
 *      removeAccount(onComplete, onError, identifier, removeKey, password) - callback to perform removal of Eigengrau account
 */
class RemoveForm extends React.Component {
    constructor(props) {
        super(props);

        try {
            var SLTRtrQryHelp = new SLTRtrQryParamHelper();
            var params = SLTRtrQryHelp.getParams(props, "remove", ["identifier","removekey"], SLTEigengrauAppErrCodes.APPERR_MISSINGPARAM);
            var decode_identifier = params.identifier;
            var decode_removekey = params.removekey;

            this.state = {
                message: null,
                identifier: decode_identifier,
                removeKey: decode_removekey,
                pwd: '',
            };
        } catch(err) {
            this.state = {
                message: err.message,
                identifier: '',
                removeKey: '',
                pwd: '',
            };
        }

        this.handleChange = this.handleInputChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);        
    }

    /**
     * handleInputChange
     *      handle edit text field changes made by the user on the form
     */
    handleInputChange(event)
    {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    /**
     * handleRemove
     *      perform action to remove account from Eigengrau
     */
    handleRemove(event)
    {
        this.props.removeAccount(
            (success) => {
                this.setState({message: 'Eigengrau account associated with ' + this.state.identifier + ' has been removed!'});
            },
            (error) => {
                this.setState({message: JSON.stringify(error)});
            },
            this.state.identifier,
            this.state.removeKey,
            this.state.pwd,
        );

        event.preventDefault();
    }

    /**
     * render
     *      React JS routine to render the UI
     */
    render() {
        const message = this.state.message;

        return(
            <React.StrictMode>
            <SLTHeader props logo={this.props.logo} rightmenuitem={{label:'Sign In',link:'/'}} />
            <form onSubmit={this.handleRemove}>
                <table className="Page_Table" name="RemoveTable">
                    <tbody>
                    <tr>
                        <td>
                            <FormMessage message={message} />
                        </td>
                    </tr>
                    <tr>
                        <td className="Page_Col">
                        Remove Account: {this.state.identifier}
                        </td>
                    </tr>
                    <tr>
                        <td className="Page_Col">
                        <input className="Edit_Ctl" type="Password" name="pwd" id="pwdFieldId" placeholder="Account Password" maxLength="255" value={this.state.pwd} onChange={this.handleChange} />
                        </td>
                    </tr>
                    <tr>
                        <td className="Page_Col">
                            <input className="Submit_Ctl" type="submit" id="removeButton" value="Remove Account" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
            </React.StrictMode>
        );
    }
}  

export default withRouter(RemoveForm);