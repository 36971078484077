/**
 * module: SLTEigengrau_api.js
 * author: Rob Blanch
 * created: 12/10/2020
 * license: see https://www.stonylanetech.com/licenses/eigengrau/license.txt for details
 * 
 * (c) 2024 Stony Lane Tech LLC
 */
import version from './version';
import sltDateTime from './sltDateTime.js';
import sltIDKeyTypeHelper from './sltIDKeyTypeHelper.js';
require('url');

const sltURLSearchParams = require('./sltURLSearchParams.js').default;
const mode = 'cors';

/**
 * SLTEigengrau_api
 *  this is the main set of routines for accessing REST APIs of
 * Eigengrau web service.
 */
class SLTEigengrau_api {
    constructor() {
        this.app_key = window.SLTEIGENBRAU_APP_KEY;
        this.session = null;
        this.listeners = [];
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        this.sltDateTime = new sltDateTime();
        this.sltIDKeyTypeHelper = new sltIDKeyTypeHelper();
    }

    /**
     * DefaultAlias
     *  return a default alias string for the user
     */
    static DefaultAlias(firstName, lastName) {
        var firstCharLastName = (lastName && lastName.length) ? lastName.charAt(0) : '';
        return (firstName && firstName.length > 0) ? firstName + firstCharLastName : '';
    }

    /**
     * LowerCaseCompareStrings
     *  perform string comparison of strings as lower case (ie. case insensitive),
     * and return comparison result equivalent to what an JS array sort
     * comparator function expects.
     */
    static LowerCaseCompareStrings(firstString, secondString) {
        var result = 0;

        if (firstString && secondString) {
            var firstStringLower = firstString.toLowerCase();
            var secondStringLower = secondString.toLowerCase();
            result = (firstStringLower < secondStringLower) ? -1 : ((firstStringLower > secondStringLower) ? 1 : 0);
        } else if (firstString) {
            result = 1;
        } else if (secondString) {
            result = -1;
        }

        return result;
    }

    /**
     * ComparePropertyNames
     *  used to sort properties by name with array.sort() capabilities in js
     */
    static ComparePropertyNames(firstProperty, secondProperty) {
        var prop1Name = (firstProperty && ("property_name" in firstProperty) === true) ? firstProperty.property_name : null;
        var prop2Name = (secondProperty && ("property_name" in secondProperty) === true) ? secondProperty.property_name : null;
        var result = SLTEigengrau_api.LowerCaseCompareStrings(prop1Name, prop2Name);

        return result;
    }

    /**
     * SortPropertiesByName
     *  return sorted properties by name (sorted in place). it is recommended to call
     * this routine for alpha sorting of properties prior to handling in table.
     */
    static SortPropertiesByName(properties) {
        if (properties) {
            if (("length" in properties) === true && properties.length) {
                properties.sort(SLTEigengrau_api.ComparePropertyNames);
            }
        }

        return properties;
    }

    /**
     * FindPropertyIndexByName
     *  return the index of the property matching name within this.state.properties. -1 if not found
     * will be returned.
     */
    static FindPropertyIndexByName(properties, property_name) {
        var idxFound = null;

        if (properties) {
            for (var idx = 0; idx < properties.length; idx++) {
                if (property_name === properties[idx].property_name) {
                    idxFound = idx;
                    break;
                }
            }    
        }

        return (idxFound !== null) ? idxFound : -1;
    }

    /**
     * DoesPropertyExist
     *  Is the property existing within the provided properties. true if exists, false if not
     */
    static DoesPropertyExist(properties, property_name) {
        var isProperty = false;
        var found_property_index = SLTEigengrau_api.FindPropertyIndexByName(properties, property_name);

        if (found_property_index >= 0) {
            isProperty = true;
        }

        return isProperty;
    }

    /**
     * PropertyValueForPropertyName
     *  provided a property set and a property name, return the property value. returns null if not found or missing property_value.
     */
    static PropertyValueForPropertyName(properties, property_name) {
        var idx = SLTEigengrau_api.FindPropertyIndexByName(properties, property_name);
        return (idx > -1) ? (("property_value" in properties[idx]) ? properties[idx].property_value : null) : null;
    }

    /**
     * buildProperUrlString
     *  take into account possible missing '/' on parts of URL. null if
     * error occurs.
     */
    buildProperUrlString(baseUri, apiName) {
        var url = null;

        if (baseUri && apiName && baseUri.length && apiName.length) {
            baseUri = (baseUri[baseUri.length-1] === '/') ? baseUri : baseUri.concat('/');
            url = baseUri + apiName;
        }

        return url;
    }

    /**
     * getApiUrl
     *  used to construct basic root api call given an api name for REST API
     */
    getApiUrl(apiName)
    {
        var uri = null;

        try {
            uri = this.buildProperUrlString(window.BASE_SLTEIGENBRAU_API, apiName);
        } catch (error) {
            console.log('SLTEigengrau_api.getApiUrl() [ERROR]: Exception encountered. error=' + JSON.stringify(error));
        }

        return (uri) ? new URL(uri) : null;    
    }

    /**
     * getSLTApiUrl
     *  used to get authentication service root api call given name for REST API
     */
    getSLTApiUrl(apiName)
    {
        var uri = null;

        try {
            uri = this.buildProperUrlString(window.BASE_SLTAUTH_API, apiName);
        } catch (error) {
            console.log('SLTEigengrau_api.getSLTApiUrl() [ERROR]: Exception encountered. error=' + JSON.stringify(error));
        }

        return (uri) ? new URL(uri) : null;
    }

    /**
     * postApplication
     *  used to create an application (if appropriate params supplied -- see doc)
     */
    postApplication(onComplete, onError, registrationKey, owner, label)
    {
        const apiUrl = this.getApiUrl('application');
        var body = JSON.stringify({ registration_key: registrationKey, 
                                    owner: owner, 
                                    label: label});
        var request =   {   method: 'POST',
                            mode: mode,
                            headers: this.headers,
                            body: body};

        fetch( apiUrl, request )
        .then (response => response.json())
        .then ( (response) => 
                            {
                                if (onComplete !== null) {
                                    onComplete(response);
                                }
                            },
                (error) => 
                        { 
                            if (onError !== null) {
                                onError({message: 'application POST Error: ' + error.message});
                            }
                        });

        return;
    }

    /**
     * getVersion
     *  returns version information for REST APIs
     */
    getVersion(onComplete, onError)
    {
        try {
            fetch(version)
                .then(r => r.text())
                .then(text => {
                    var siteversion = text.toString();

                    const apiUrl = this.getApiUrl('version');
                    fetch(apiUrl)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                result.version = siteversion;
                                onComplete(result);
                            },
                            (error) => {
                                onError(error);
                            }
                        )
                        .catch(error => {
                            onError(error);
                        });
                })
                .catch(error => {
                    onError(error);
                });
        } catch (error) {
            onError(error);
        }

        return;
    }

    /**
     * setSessionCache
     *  sets session information for cache purposes. should only be used by calls within this module of code (ie. private)
     */
    setSessionCache(session)
    {
        var listener_type;
        var old_session = this.session;
        var new_session = session;
        var param1;
        var param2;

        if (session) {
            sessionStorage.setItem("session", JSON.stringify(session));
        } else {
            sessionStorage.removeItem("session");
        }
        sessionStorage.removeItem("user_alias");        /* force a re-load upon request in getUserAlias() */
        this.session = session;

        if (old_session && new_session) {
            listener_type = 'session-refresh';
            param1 = new_session;
            param2 = old_session;
        } else if (new_session) {
            listener_type = 'session-login';
            param1 = new_session;
        } else {
            listener_type = 'session-logout';
            param1 = old_session;
        }

        if (listener_type) {
            var listeners = (this.listeners && this.listeners[listener_type]) ? this.listeners[listener_type] : null;

            for (var idxListener = 0; listeners && idxListener < listeners.length; idxListener++) {
                var listener = listeners[idxListener];
                if (listener) {
                    listener(param1, param2);
                }
            }
        }
    }

    /**
     * getSessionCache
     *  called to retrieve the session from local object cache or fall back on session storage
     */
    getSessionCache() {
        var session = null;

        if (this.doesSessionExist()) {
            session = this.session;
        } else {
            session = JSON.parse(sessionStorage.getItem("session"));
            if (session) {
                this.session = session;
            }
        }

        return session;
    }

    /**
     * doesSessionExist
     *      called to detect whether or not a session exists yet
     */
    doesSessionExist() {
        return (this.session) ? true : false;
    }

    /**
     * clearSessionCache
     *  called to remove the session from the cache
     */
    clearSessionCache() {
        this.setSessionCache(null);
    }

    /**
     * registerListener
     *  used to register a listener for an event generated by sltorangeapi
     * returns index of listener added for event starting with zero
     */
    registerListener(event, addListener) {
        var index = 0;

        if (this.listeners && this.listeners[event]) {
            this.listeners[event].push(addListener);
            index = this.listeners[event].legnth;
        } else {
            var listeners = [ addListener ];
            this.listeners[event] = listeners;
        }

        return index;
    }

    /**
     * unregisterListener
     *  used to remove a listener for an event of sltorangeapi
     * returns true if listener found & removed; false if not
     */
    unregisterListener(event, removeListener) {
        var listeners = (this.listeners) ? this.listeners[event] : null;
        var newListeners = null;
        var fRemoved = false;

        for (var idx = 0; listeners && idx < listeners.length; idx++) {
            var listener = listeners[idx];
            if (listener && listener === removeListener) {
                fRemoved = true;
                continue;
            }

            if (newListeners === null) {
                newListeners[0] = listener;
            } else {
                newListeners.push(listener);
            }
        }

        this.listeners[event] = newListeners;

        return fRemoved;
    }

    /**
     * getSession
     *  will call onComplete with session id information and onError if there is an issue. identifier & password are required if first session being obtained.
     */
    getSession(onComplete, onError, identifier = null, password = null)
    {
        const app_key = this.app_key;
        var body = null;
        var id_key_type = null;
        
        if (identifier) {
            id_key_type = this.sltIDKeyTypeHelper.id_key_type(identifier);
        } else if (this.session) {
            identifier = this.session.identifier;
            id_key_type = this.sltIDKeyTypeHelper.id_key_type(identifier);
        } else {
            var session = this.getSessionCache();
            if (session) {
                identifier = this.session.identifier;
                id_key_type = this.sltIDKeyTypeHelper.id_key_type(identifier);
            }
        }

        /**
         * make sure we picked up identifier & id_key_type somehow (either cached or supplied)
         */
        if (identifier === null || id_key_type === null) {
            if (identifier === null) {
                onError({message: 'SLTEigengrau_api::getSessionID() no identifier can be found to obtain session.'});
            }
    
            if (id_key_type === null) {
                onError({message: 'SLTEigengrau_api::getSessionID() no id_key_type was discovered for identifier to obtain session. might be parsing identifier issue.'});
            }    
        } else {
            /**
             *  1. If we have session cached, test to see if we need to obtain new sessionid, or can use existing cached sessionid
             *      a. use "witching window" which is time period before expiration to provide buffer for execution with session id by app
             *      b. if sessionid is expired or going to be expired within "witching window" then construct JSON body to refresh the session id
             *  2. If no session cached, construct JSON body with app_key, identifier, and password to obtain first session with service
             *  3. If body exists, execute REST API POST call to obtain new session
             *      a. cache session and call onComplete upon successful fetch of new session information
             *      b. call onError if REST API call not successful 
             *  4. If no body and cached session exists
             *      a. if onComplete supplied, then call it
             *      b. if no onComplete just return to caller
             *  5. If no REST API CALL in step 3 and no cached session for step 4 and have onError defined, call it with error info
             */
            if (this.session !== null) {
                const witchingWindow = 120*1000;    /* # milliseconds within expirationdate to grab new session */
                var witchingDate = this.sltDateTime.parse(this.session.expirationdate, true)-witchingWindow;
                var utcNow = this.sltDateTime.utcNow();

                if (witchingDate <= utcNow) {
                    body = JSON.stringify({sessionid:this.session.sessionid, refreshtoken:this.session.refreshtoken, id_key_type: id_key_type, identifier:this.session.identifier});
                }
            } else if (app_key !== null && identifier !== null && password !== null) {
                body = JSON.stringify({app_key: app_key, id_key_type: id_key_type, identifier: identifier, password: password});
            }
            
            if (body !== null) {
                const apiUrl = this.getSLTApiUrl('sessionid');
                var request =   {
                                method: 'POST',
                                mode: mode,
                                headers: this.headers,
                                body: body,
                                };

                fetch( apiUrl, request )
                .then (response => response.json())
                .then ( (response) => {
                                        if (response.sessionid != null) {
                                            response.identifier = identifier;
                                            this.setSessionCache(response);
                                            if (onComplete !== null) {
                                                onComplete(response);
                                            }
                                        } else {
                                            if (onError !== null) {
                                                onError({message: JSON.stringify(response)});
                                            }
                                        }
                                    },
                        (error) => { 
                                        if (onError !== null) {
                                            onError({message: 'sessionid POST Error: ' + error.message});
                                        }
                                    });
            } else if (this.session) {
                if (onComplete !== null) {
                    /** cached session is good to return **/
                    onComplete(this.session);
                }
            } else {
                onError({message: 'SLTEigengrau_api::getSessionID() no cached session && missing mandatory app_key, identifier, or password parameter to obtain session.'});
            }
        }

        return;
    }

    /**
     * deleteSession
     *  called to log a user out of a session.
     */
    deleteSession(onComplete, onError)
    {
        this.getSession(
            (session) => {
                const apiUrl = this.getSLTApiUrl('sessionid');
                var request =   {
                                method: 'DELETE',
                                mode: mode,
                                headers: this.headers,
                                body: JSON.stringify({sessionid: session.sessionid}),
                                };
        
                fetch( apiUrl, request )
                .then ( response => response.json() )
                .then ( (response) => {
                                        if (response.success !== null) {
                                            /** need to null out session cache, since no longer valid **/
                                            this.clearSessionCache();

                                            if (onComplete !== null) {
                                                onComplete(response);
                                            }
                                        } else {
                                            if (onError !== null) {
                                                onError({message: JSON.stringify(response)});
                                            }
                                        }
                                    },
                        (error) => { 
                                        if (onError !== null) {
                                            onError({message: 'session DELETE Error: ' + error.message});
                                        }
                                    },
                                );
            },
            (error) => {
                if (onError !== null) {
                    onError(error);
                }
            }
        )
    }

    /**
     * getUserAlias
     *  used to obtain alias of user (leverages cache by default).
     * will call onComplete with alias string or null if there is an error obtaining the user's alias
     */
    getUserAlias(onComplete, fUseCache = true) {
        var alias = sessionStorage.getItem("user_alias");

        if ((alias === null || alias === undefined || alias === '') || fUseCache === false) {
            this.getUser(
                        (userinfo) => {
                            sessionStorage.setItem("user_alias", userinfo.alias);
                            onComplete(userinfo.alias);
                            },
                        (error) => {
                            console.log('SLTEigengrau_api::getUserAlias() [ERROR]: failed to obtain user alias from service. error.message=' + error.message);
                            sessionStorage.setItem("user_alias", null);
                            onComplete(null);
                        });
        } else {
            onComplete(alias);
        }
    }

    /**
     * getUser
     *  used to obtain user information from JME4 REST service
     */
    getUser(onComplete, onError)
    {
        /**
         *  1. obtain session (or throw error if not possible)
         *  2. construct user API GET request and if not error return results using onComplete callback
         */
        this.getSession(
            (session) => {
                var url = this.getApiUrl('user');

                var params = {sessionid: session.sessionid};
                url.search = new sltURLSearchParams(params).getSearchString();

                fetch(url)
                .then (response => response.json())
                .then ( (response) => { 
                                        if (response.user_key !== null) {
                                            onComplete(response);
                                        } else {
                                            onError(response);
                                        }
                                    },
                        (error) => { 
                                        onError({message: 'user GET Error: ' + error.message});
                                    })
            },
            (error) => {
                onError(error);
            }
        )
    }

    /**
     * createUser
     *  used to register a new user account
     */
    createUser(onComplete, onError, firstName, lastName, alias, identifier, password)
    {
        const apiUrl = this.getApiUrl('user');
        var id_key_type = this.sltIDKeyTypeHelper.id_key_type(identifier);
        var request =   {
                        method: 'POST',
                        mode: mode,
                        headers: this.headers,
                        body: JSON.stringify({  app_key: this.app_key, 
                                                firstname: firstName, 
                                                lastname: lastName, 
                                                alias: alias, 
                                                id_key_type: id_key_type, 
                                                identifier: identifier, 
                                                password: password}),
                        };

        fetch( apiUrl, request )
        .then (response => response.json())
        .then ( (response) => {
                                if (response.user_key !== null) {
                                    if (onComplete !== null) {
                                        onComplete(response);
                                    }
                                } else {
                                    if (onError !== null) {
                                        onError({message: JSON.stringify(response)});
                                    }
                                }
                            },
                (error) => { 
                                if (onError !== null) {
                                    onError({message: 'user POST Error: ' + error.message});
                                }
                            });
    }

    /**
     * updateUser
     *  used to update an existing user account with new information in jme4
     */
    updateUser(onComplete, onError, firstName, lastName, alias, password)
    {
        this.getSession(
            (session) => {
                const apiUrl = this.getApiUrl('user');
                var request =   {
                                method: 'POST',
                                mode: mode,
                                headers: this.headers,
                                body: JSON.stringify({sessionid: session.sessionid, firstname: firstName, lastname: lastName, alias: alias, password: password}),
                                };
        
                fetch( apiUrl, request )
                .then ( response => response.json() )
                .then ( (response) => {
                                        if (response.user_key) {
                                            if (onComplete !== null) {
                                                onComplete(response);
                                            }
                                        } else {
                                            if (onError !== null) {
                                                onError({message: JSON.stringify(response)});
                                            }
                                        }
                                    },
                        (error) => { 
                                        if (onError !== null) {
                                            onError({message: 'user POST Error: ' + error.message});
                                        }
                                    },
                                );
            },
            (error) => {
                if (onError !== null) {
                    onError(error);
                }
            }
        )
    }

    /**
     * persistUserProperties
     *  stash the user properties with the service.
     * should_put flag is used to specify whether all user properties are to be posted
     * for user in this call, or only the properties being supplied should be put for the user.
     * if should_put is true, then only properties supplied will be added or modified for user.
     * if should_put is false, then user_properties will be applied to user as if it represents
     * the complete set of user properties, so existing properties are removed and then the
     * user_properties are persisted for the user in this scenario.
     */
    persistUserProperties(onComplete, onError, should_put, user_properties) {
        this.getSession(
            (session) => {
                const apiUrl = this.getApiUrl('user');
                var request =   {
                                method: 'POST',
                                mode: mode,
                                headers: this.headers,
                                body: JSON.stringify({sessionid: session.sessionid, put_properties: should_put, user_properties: user_properties}),
                                };
        
                fetch( apiUrl, request )
                .then ( response => response.json() )
                .then ( (response) => {
                                        if (response.user_key) {
                                            if (onComplete !== null) {
                                                onComplete(response);
                                            }
                                        } else {
                                            if (onError !== null) {
                                                onError({message: JSON.stringify(response)});
                                            }
                                        }
                                    },
                        (error) => { 
                                        if (onError !== null) {
                                            onError({message: 'user properties POST Error: ' + error.message});
                                        }
                                    },
                                );
            },
            (error) => {
                if (onError !== null) {
                    onError(error);
                }
            }
        );
    }

    /**
     * validateAccount
     *  called to validate a user account for usage (note: need validationkey sent to user identifier account)
     */
    validateAccount(onComplete, onError, identifier, validationkey, password)
    {
        const apiUrl = this.getSLTApiUrl('validationkey');
        var id_key_type = this.sltIDKeyTypeHelper.id_key_type(identifier);
        var request =   {
                        method: 'POST',
                        mode: mode,
                        headers: this.headers,
                        body: JSON.stringify({id_key_type: id_key_type, identifier: identifier, validationkey: validationkey, password: password}),
                        };

        fetch( apiUrl, request )
        .then ( response => response.json() )
        .then ( (response) => {
                                if (response.success === true) {
                                    if (onComplete !== null) {
                                        onComplete(response);
                                    }
                                } else {
                                    if (onError !== null) {
                                        onError({message: 'Failed activation attempt for account ' + identifier + '. Service returned: ' + JSON.stringify(response)});
                                    }
                                }
                            },
                (error) => { 
                                if (onError !== null) {
                                    onError({message: 'validationkey POST Error: ' + error.message});
                                }
                            },
                        );
    }

    /**
     * removeAccount
     *  called in response to remove key to approve user account removal
     */
    removeAccount(onComplete, onError, identifier, removeKey, password)
    {
        const apiUrl = this.getSLTApiUrl('removekey');
        var id_key_type = this.sltIDKeyTypeHelper.id_key_type(identifier);
        var request =   {
                        method: 'POST',
                        mode: mode,
                        headers: this.headers,
                        body: JSON.stringify({id_key_type: id_key_type, identifier: identifier, removekey: removeKey, password: password}),
                        };

        fetch( apiUrl, request )
        .then ( response => response.json() )
        .then ( (response) => {
                                if (response.success === true) {
                                    if (onComplete !== null) {
                                        onComplete(response);
                                    }
                                } else {
                                    if (onError !== null) {
                                        onError({message: 'Failed remove account attempt for account ' + identifier + '. Service returned: ' + JSON.stringify(response)});
                                    }
                                }
                            },
                (error) => { 
                                if (onError !== null) {
                                    onError({message: 'removekey POST Error: ' + error.message});
                                }
                            },
                );
    }

    /**
     * resetPassword
     *  reset the password for user account
     */
    resetPassword(onComplete, onError, identifier, resetKey, newPassword)
    {
        const apiUrl = this.getSLTApiUrl('resetkey');
        var id_key_type = this.sltIDKeyTypeHelper.id_key_type(identifier);
        var request =   {
                        method: 'POST',
                        mode: mode,
                        headers: this.headers,
                        body: JSON.stringify({id_key_type: id_key_type, identifier: identifier, resetkey: resetKey, new_password: newPassword}),
                        };

        fetch( apiUrl, request )
        .then ( response => response.json() )
        .then ( (response) => {
                                if (response.success === true) {
                                    if (onComplete !== null) {
                                        onComplete(response);
                                    }
                                } else {
                                    if (onError !== null) {
                                        onError({message: 'Failed reset password attempt for account ' + identifier + '. Service returned: ' + JSON.stringify(response)});
                                    }
                                }
                            },
                (error) => { 
                                if (onError !== null) {
                                    onError({message: 'reset POST Error: ' + error.message});
                                }
                            },
                );
    }

    /**
     * getUsageStatistics
     *  return information regarding usage statistics of service
     */
    getUsageStatistics(onComplete, onError) {
        this.getSession(
            (session) => {
                const url = this.getApiUrl('usagestatistics');

                var params = {sessionid: session.sessionid};
                url.search = new sltURLSearchParams(params).getSearchString();

                fetch(url)
                .then (response => response.json())
                .then ( (response) => {
                            onComplete(response);
                        },
                        (error) => {
                            onError(error);
                        })
            },
            (error) => {
                onError(error);
            }
        )
    }

    /**
     * postRegistration
     *  create a Eigengrau device registration
     */
    postRegistration(onComplete, onError, sansecid, device_name) {
        this.getSession(
            (session) => {
                const apiUrl = this.getApiUrl('registration');
                var peach_app_key = window.SLTPEACH_APP_KEY;
                var request =   {
                    method: 'POST',
                    mode: mode,
                    headers: this.headers,
                    body: JSON.stringify({  sessionid: session.sessionid, 
                                            peach_app_key: peach_app_key,
                                            sansecid: sansecid, 
                                            device_name: device_name}),
                    };

                    fetch( apiUrl, request )
                    .then ( response => response.json() )
                    .then ( (response) => {
                                            if (response.success !== null) {
    
                                                if (onComplete !== null) {
                                                    onComplete(response);
                                                }
                                            } else {
                                                if (onError !== null) {
                                                    onError({message: JSON.stringify(response)});
                                                }
                                            }
                                        },
                            (error) => { 
                                            if (onError !== null) {
                                                onError({message: 'registration POST Error: ' + error.message});
                                            }
                                        },
                                    );
                },
            (error) => {
                if (onError !== null) {
                    onError(error);
                }    
            }
        )
    }

    /**
     * getRegistration
     *  return the user Eigengrau device registration via a callback
     */
    getRegistration(onComplete, onError) {
        this.getSession(
            (session) => {
                const url = this.getApiUrl('registration');

                var params = {  sessionid: session.sessionid };
                url.search = new sltURLSearchParams(params).getSearchString();

                fetch(url)
                .then (response => response.json())
                .then ( (response) => {
                            onComplete(response);
                        },
                        (error) => {
                            onError({message: 'registration GET Error: ' + error.message});
                        })
            },
            (error) => {
                if (onError) {
                    onError(error);
                }
            }
        )
    }

    /**
     * deleteRegistration
     *  remove an Orange user registration of a Eigengrau device
     */
    deleteRegistration(onComplete, onError, device_name) {
        this.getSession(
            (session) => {
                const apiUrl = this.getApiUrl('registration');
                var request =   {
                    method: 'DELETE',
                    mode: mode,
                    headers: this.headers,
                    body: JSON.stringify({  sessionid: session.sessionid, 
                                            device_name: device_name}),
                    };

                fetch( apiUrl, request )
                .then ( response => response.json() )
                .then ( (response) => {
                                        if (response.success !== null) {

                                            if (onComplete !== null) {
                                                onComplete(response);
                                            }
                                        } else {
                                            if (onError !== null) {
                                                onError({message: JSON.stringify(response)});
                                            }
                                        }
                                    },
                        (error) => { 
                                        if (onError !== null) {
                                            onError({message: 'registration DELETE Error: ' + error.message});
                                        }
                                    },
                                );
            },
            (error) => {
                if (onError !== null) {
                    onError(error);
                }
            }        
        )
    }

    /**
     * getMarker
     *  retrieve device markers information from service
     */
    getMarker(onComplete, onError, device_name) {
        this.getSession(
            (session) => {
                const url = this.getApiUrl('marker');

                var params = {  sessionid: session.sessionid,
                                device_name: device_name };
                url.search = new sltURLSearchParams(params).getSearchString();

                fetch(url)
                .then (response => response.json())
                .then ( (response) => {
                            onComplete(response);
                        },
                        (error) => {
                            onError({message: 'marker GET Error: ' + error.message});
                        })
            },
            (error) => {
                if (onError) {
                    onError(error);
                }
            }
        )
    }

    /**
     * postMarker
     *  create/modify a marker for a device on the service
     */
    postMarker(onComplete, onError, device_name, marker) {
        this.getSession(
            (session) => {
                const apiUrl = this.getApiUrl('marker');
                var request =   {
                    method: 'POST',
                    mode: mode,
                    headers: this.headers,
                    body: JSON.stringify({  sessionid: session.sessionid, 
                                            device_name: device_name,
                                            marker: marker }),
                    };

                    fetch( apiUrl, request )
                    .then ( response => response.json() )
                    .then ( (response) => {
                                            if (response.success !== null) {
    
                                                if (onComplete !== null) {
                                                    onComplete(response);
                                                }
                                            } else {
                                                if (onError !== null) {
                                                    onError({message: JSON.stringify(response)});
                                                }
                                            }
                                        },
                            (error) => { 
                                            if (onError !== null) {
                                                onError({message: 'marker POST Error: ' + error.message});
                                            }
                                        },
                                    );
                },
            (error) => {
                if (onError !== null) {
                    onError(error);
                }    
            }
        )
    }

    /**
     * deleteMarker
     *  remove marker for device on the service
     */
    deleteMarker(onComplete, onError, device_name, markername) {
        this.getSession(
            (session) => {
                const apiUrl = this.getApiUrl('marker');
                var request =   {
                    method: 'DELETE',
                    mode: mode,
                    headers: this.headers,
                    body: JSON.stringify({  sessionid: session.sessionid, 
                                            device_name: device_name,
                                            markername: markername }),
                    };

                fetch( apiUrl, request )
                .then ( response => response.json() )
                .then ( (response) => {
                                        if (response.success !== null) {

                                            if (onComplete !== null) {
                                                onComplete(response);
                                            }
                                        } else {
                                            if (onError !== null) {
                                                onError({message: JSON.stringify(response)});
                                            }
                                        }
                                    },
                        (error) => { 
                                        if (onError !== null) {
                                            onError({message: 'marker DELETE Error: ' + error.message});
                                        }
                                    },
                                );
            },
            (error) => {
                if (onError !== null) {
                    onError(error);
                }
            }        
        )
    }

    /**
     * getDeviceData
     *  retrieve device data from the service within range of starttime and stoptime
     */
    getDeviceData(onComplete, onError, device_name, starttime, stoptime, start_data_id, stop_data_id, datacountlimit) {
        this.getSession(
            (session) => {
                const url = this.getApiUrl('devicedata');

                var params = {  sessionid: session.sessionid,
                                device_name: device_name,
                                datacountlimit: datacountlimit };

                if (starttime) {
                    params.capturestart = starttime;
                }

                if (stoptime) {
                    params.capturestop = stoptime;
                }

                if (start_data_id) {
                    params.start_data_id = start_data_id;
                }

                if (stop_data_id) {
                    params.stop_data_id = stop_data_id;
                }

                url.search = new sltURLSearchParams(params).getSearchString();

                fetch(url)
                .then (response => response.json())
                .then ( (response) => {
                            onComplete(response);
                        },
                        (error) => {
                            onError({message: 'devicedata GET Error: ' + error.message});
                        })
            },
            (error) => {
                if (onError) {
                    onError(error);
                }
            }
        )
    }

    /**
     * getDeviceDataMetaData
     *  retrieve device data meta-data from the service
     */
    getDeviceDataMetaData(onComplete, onError, device_name, starttime, stoptime, pagesize) {
        this.getSession(
            (session) => {
                const url = this.getApiUrl('devicedatametadata');

                var params = {  sessionid: session.sessionid,
                                device_name: device_name,
                                capturestart: starttime,
                                capturestop: stoptime,
                                pagesize: pagesize };
                url.search = new sltURLSearchParams(params).getSearchString();

                fetch(url)
                .then (response => response.json())
                .then ( (response) => {
                            onComplete(response);
                        },
                        (error) => {
                            onError({message: 'devicedatametadata GET Error: ' + error.message});
                        })
            },
            (error) => {
                if (onError) {
                    onError(error);
                }
            }
        )
    }
}

export default SLTEigengrau_api;