/**
 * module: ValidationForm.js
 * author: Rob Blanch
 * usage: rendering logic for Eigengrau validate account form
 * license: see https://www.stonylanetech.com/licenses/Eigengrau/license.txt for details
 * 
 * (C) 2020 Stony Lane Tech LLC
 */
import React from 'react';
import {withRouter} from 'react-router';
import SLTHeader from './SLTHeader.js';
import FormMessage from './FormMessage';
import SLTEditControl from './SLTEditControl';
import SLTSubmitControl from './SLTSubmitControl.js';

const SLTRtrQryParamHelper = require('./SLTRtrQryParamHelper.js').default;
const SLTEigengrauAppErrCodes = require('./SLTEigengrauAppErrCodes.js').SLTEigengrauAppErrCodes;
const SLTEigengrauAppErrHelp = new (require('./SLTEigengrauAppErrHelper.js').default) ();

/**
 * class: ValidationForm
 * usage: form used to validate a new Eigengrau Admin user account
 * props:
 *      logo - logo to show in header of form
 *      validateAccount(onComplete, onError, identifier, validationkey, password) - callback to validate a Eigengrau Admin account via service
 */
class ValidationForm extends React.Component {
    constructor(props) {
        super(props);

        try {
            var SLTRtrQryHelp = new SLTRtrQryParamHelper();
            var params = SLTRtrQryHelp.getParams(props, "validate", ["identifier","validationkey"], SLTEigengrauAppErrCodes.APPERR_MISSINGPARAM);
            var decode_identifier = params.identifier;
            var decode_validationkey = params.validationkey;    
    
            this.state = {
                message: "Please enter your password to validate account " + props.match.params.identifier,
                validateAttempted: false,
                identifier: decode_identifier,
                validationkey: decode_validationkey,
                pwd: '',
            };
        } catch(err) {
            this.state = {
                message: "No validation key found on query line, so need to find it in email, etc. and paste into Key below.",
                validateAttempted: false,
                identifier: '',
                validationkey: '',
                pwd: '',
            }
        }
    
        this.handleChange = this.handleInputChange.bind(this);
        this.handleValidate = this.handleValidate.bind(this);    
    }

    /**
     * handleInputChange
     *      logic to handle user edits on the form
     */
    handleInputChange(event)
    {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    /**
     * process the request to validate the Eigengrau Admin user account via callback to service
     */
    handleValidate(event)
    {
        const identifier = this.state.identifier;
        const validationkey = this.state.validationkey;
        const pwd = this.state.pwd;

        this.props.validateAccount(
            (success) => {
                this.setState({message: identifier + ' account has been activated!'});
            },
            (error) => {
                this.setState({message: SLTEigengrauAppErrHelp.getUserErrMsgFromError(error)})
            },
            identifier,
            validationkey,
            pwd
        );

        event.preventDefault();
    }

    /**
     * render
     *      react js called routine to layout UI
     */
    render() {
        const message = this.state.message;
    
        return(
            <React.StrictMode>
            <SLTHeader props logo={this.props.logo} rightmenuitem={{label:'Sign In',link:'/'}} />
            <form onSubmit={this.handleValidate}>
                <div className="ProfileHeader">
                    <div className="ProfileHeaderLabel">
                        Validate User Account
                    </div>
                </div>

                <table className="Page_Table" name="ValidateTable">
                    <tbody>
                        <tr>
                            <td>
                                <FormMessage message={message} />
                            </td>
                        </tr>

                        <SLTEditControl props
                                        name="validationkey"
                                        key="validationkey"
                                        value={this.state.validationkey}
                                        onChange={this.handleChange}
                                        maxlength={255}
                                        label="Key:" />
                        <SLTEditControl props
                                        name="identifier"
                                        key="identifier"
                                        value={this.state.identifier}
                                        onChange={this.handleChange}
                                        maxlength={255}
                                        label="Identifier:" />
                        <SLTEditControl props
                                        name="pwd"
                                        key="password"
                                        value={this.state.pwd}
                                        onChange={this.handleChange}
                                        maxlength={255}
                                        label="Password:" />

                        <SLTSubmitControl   props
                                            name="submit"
                                            key="submit"
                                            value="Validate" />
                    </tbody>
                </table>
            </form>
            </React.StrictMode>
        );
    }
}  

export default withRouter(ValidationForm);