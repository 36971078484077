/**
 * module: SLTEigengrauAccountTab.js
 * author: Rob Blanch
 * usage: rendering logic for Eigengrau account tab
 * license: see https://www.stonylanetech.com/licenses/Eigengrau/license.txt for details
 * 
 * (C) 2024 Stony Lane Tech LLC
 */
import React from 'react';
import FormMessage from './FormMessage.js';
import SLTHeaderNavBar from './SLTHeaderNavBar.js';
require('url');

/**
 * class: SLTEigengrauAccountTab
 * usage: rendering logic for the account tab in the Eigengrau Admin Application
 * props:
 *      logoItem - logo information to display in nav header of form
 *      isNavBarExpanded - true/false flag indicating if nav bar menu should be expanded
 *      setNavBarExpanded(newExpandState) - callback to alter state of isNavBarExpanaded to newExpandState value
 *      getUser(onComplete, onError) - routine to call for obtaining user information for account
 *      updateUser(onComplete, onError, firstName, lastName, alias, password) - routine to call for updating changes made to user to service
 */
class SLTEigengrauAccountTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            user_key: null,
            firstName: '',
            lastName: '',
            alias: '',
            doDefaultAlias: false,
            identifier: '',
            pwd: '',
            sltEigengrauapi: props.sltEigengrauapi,
            accountLoaded: false,
        };

        this.handleDefaults = this.handleDefaults.bind(this);
        this.handleChange = this.handleInputChange.bind(this);
        this.handleAccountUpdate = this.handleAccountUpdate.bind(this);
    }

    /**
     * componentDidMount
     *      react js routine called right before component mounts
     */
    componentDidMount()
    {
        if (this.state.accountLoaded === false) {
            this.props.getUser(
                (user) => {
                    this.setState({firstName: user.firstname, lastName: user.lastname, alias: user.alias, identifier: user.identifier, user_key: user.user_key, accountLoaded: true});
                },
                (error) => {
                    this.setState({message: JSON.stringify(error)});
                },
            );
        }
    }

    /**
     * handleInputChange
     *      routine called to handle user edits in the text fields on tab
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name === 'alias') {
            var isDefaultAlias = (value && value !== '') ? false : true;
            this.setState({doDefaultAlias: isDefaultAlias});
        }
    }

    /**
     * handleDefaults
     *      routine called to handle populating defaults on tab in fields
     */
    handleDefaults() {
        if (this.state.doDefaultAlias) {
            /** TODO ROB 3/26/20: Need to get default alias here, just putting in '' for now **/
            this.setState({alias: this.props.DefaultAlias(this.state.firstName, this.state.lastName)});
        }
    }

    /**
     * handleAccountUpdate
     *      routine called to handle saving account changes to service
     */
    handleAccountUpdate(event) {

        this.props.updateUser(
            (response) => {
                this.setState({message: response.message});
            },
            (error) => {
                this.setState({message: JSON.stringify(error)});
            },
            this.state.firstName,
            this.state.lastName,
            this.state.alias,
            this.state.pwd,
        );
    }

    /**
     * addHeader
     *  return title for table
     */
    addHeader() {
        return (
            <div className="ProfileHeader">
                <div className="ProfileHeaderLabel">
                    Account
                </div>
            </div>
        );
    }

    /**
     * render
     *  reactjs routine called to render UI
     */
    render() {
        const message = this.state.message;
        const navItems = [
            {link:'#',label:'Update',icon:null,onclick:() => {return this.handleAccountUpdate()}},
            {link:'/logout',label:'Sign Out',icon:null},
        ];

        return(
            <React.StrictMode>
            <SLTHeaderNavBar    props
                                logoItem={this.props.logoItem}
                                navItems={navItems}
                                isNavBarExpanded={this.props.isNavBarExpanded}
                                setNavBarExpanded={this.props.setNavBarExpanded} />

            <form onSubmit={this.handleAccountUpdate}>
                {this.addHeader()}
                <table className="Page_Table" name="AccountTable">
                <tbody>
                    <tr>
                        <td>
                            <FormMessage message={message} />
                        </td>
                    </tr>
                    <tr>

                    </tr>
                    <tr>
                        <td className="Page_Col">
                        <input className="Edit_Ctl" type="text" name="firstName" id="firstNameId" placeholder="First Name" maxLength="255" value={this.state.firstName} onChange={this.handleChange} onBlur={this.handleDefaults} />
                        </td>
                    </tr>
                    <tr>
                        <td className="Page_Col">
                        <input className="Edit_Ctl" type="text" name="lastName" id="lastNameId" placeholder="Last Name" maxLength="255" value={this.state.lastName} onChange={this.handleChange}  onBlur={this.handleDefaults}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="Page_Col">
                        <input className="Edit_Ctl" type="text" name="alias" id="aliasId" placeholder="Alias" maxLength="255" value={this.state.alias} onChange={this.handleChange}  onBlur={this.handleDefaults} />
                        </td>
                    </tr>
                    <tr>
                        <td className="Page_Col">
                        <input className="Edit_Ctl" type="Password" name="pwd" id="pwdFieldId" placeholder="Password" maxLength="255" value={this.state.pwd} onChange={this.handleChange} />
                        </td>
                    </tr>
                </tbody>
                </table>
            </form>
            </React.StrictMode>
        );
    }
}  

export default SLTEigengrauAccountTab;