/**
 ** module: LoginForm.js
 ** usage: log user into Eigengrau Application
 ** created: 12/10/2020
 ** owner: Rob Blanch
 **/
import React from 'react';
import {withRouter} from 'react-router-dom';
import SLTHeaderNavBar from './SLTHeaderNavBar.js';
import FormMessage from './FormMessage.js';
import SLTEditControl from './SLTEditControl.js';
import SLTSubmitControl from './SLTSubmitControl.js';
import SLTEigengrauAppErrHelper from './SLTEigengrauAppErrHelper.js';

/**
 * class: LoginForm
 * usage: used to provide user ability to login into Admin account in Eigengrau Application
 * props:
 *      logoItem - logo information to display in nav header of form
 *      isNavBarExpanded - true/false flag indicating if nav bar menu should be expanded
 *      setNavBarExpanded(newExpandState) - callback to alter state of isNavBarExpanaded to newExpandState value
 *      getSession(onComplete, onError, identifier, password) - callback routine to obtain a sessionid
 */
class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        var isMissingProps = true;

        if (this.props !== undefined && this.props !== null &&
            this.props.logoItem !== undefined && this.props.logoItem !== null &&
            this.props.isNavBarExpanded !== undefined && this.props.isNavBarExpanded !== null &&
            this.props.setNavBarExpanded !== undefined && this.props.setNavBarExpanded !== null &&
            this.props.getSession !== undefined && this.props.getSession !== null) {
                isMissingProps = false;
        }

        this.state = {
            isMissingProps: isMissingProps,
            message: null,
            error: null,
            email: '',
            pwd: '',
        };

        this.handleChange = this.handleInputChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    /**
     * handleInputChange
     *      routine to maintain state relative to user edits in UI
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    /**
     * handleLogin
     *      log the user into an Admin user account for Eigengrau Admin App
     */
    handleLogin(event) {
        const email = this.state.email;
        const pwd = this.state.pwd;

        this.props.getSession(
            (result) => {
                /**
                 * navigate to first screen
                 */
                this.props.history.push('/account');
            },
            (error) => {
                var msg;
                var errHelper = new SLTEigengrauAppErrHelper();

                var errors = errHelper.getErrorsInMessage(error.message);
                if (errors && errors.length > 0 && errors[0] === "SLT_PROC_ERR [10000]") {
                    msg = "Sign In failure, identifier or password not valid."
                } else {
                    msg = JSON.stringify(error);
                }

                this.setState({error: error, message: msg});
            },
            email,
            pwd);

        event.preventDefault();
    }

    /**
     * render
     *      routine called by react js to layout UI
     */
    render() {
        const error = this.state.error;
        const message = this.state.message;
        const navItems = [
            {link:'/version',label:'Version',icon:null},
            {link:'/register',label:'Register',icon:null}
        ];

        return(
            <React.StrictMode>
            <SLTHeaderNavBar    props
                                logoItem={this.props.logoItem}
                                navItems={navItems}
                                isNavBarExpanded={this.props.isNavBarExpanded}
                                setNavBarExpanded={this.props.setNavBarExpanded} />
            <form onSubmit={this.handleLogin} style={{"paddingTop":"75px"}}>
                <table className="Page_Table" name="LoginTable" align="center">
                    <tbody>
                    <tr>
                        <td>
                            <FormMessage error={error} message={message} />
                        </td>
                    </tr>

                    <SLTEditControl props
                                    key="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    maxlength={255}
                                    label="Identifier:" />
                    <SLTEditControl props
                                    key="pwd"
                                    name="pwd"
                                    value={this.state.pwd}
                                    onChange={this.handleChange}
                                    maxlength={255}
                                    label="Password:" />
                    <SLTSubmitControl   props
                                        key="submit"
                                        name="submit"
                                        value="Sign In" />
                    </tbody>
                </table>
            </form>
            </React.StrictMode>
        );
    }
}  

export default withRouter(LoginForm);