/**
 * module: FromMessage.js
 * usage: utility to place a message on to form
 * created: 2/1/2019
 * owner: Rob Blanch
 */
import React from 'react';

/**
 * withReturnButton
 *      returns message bar with a return button
 */
function withReturnButton(props) {
    var classname = (props.error) ? "Msg_Ctl_Err" : "Msg_Ctl_Ok";

    return (
        <div>
            <p className={classname}>{props.message}</p>
            <input type="image" src={require('./slt_return_icon/slt_return_icon.png')} name="return" className="slt_button" id="return_button" alt="return"/>
        </div>
    );
}

/**
 * withoutReturnButton
 *      returns message bar without a return button
 */
function withoutReturnButton(props) {
    if (props.error) {
        return (
            <p style={{backgroundColor: "#FCD1D1", marginTop: ".25em"}}>{props.message}</p>
        );    
    } else {
        return (
            <p style={{backgroundColor: "#D1FCD1", marginTop: ".25em"}}>{props.message}</p>
        );    
    }
}

/**
 * HomeMessage
 *  function to call to provide a message with optional return button
 */
function HomeMessage(props) {
    return (props.message != null && props.message.length) ?  ((props.wantReturnButton) ? withReturnButton(props) : withoutReturnButton(props)) : null;
}

export default HomeMessage;