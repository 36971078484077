/**
 * module: SLTSubmitControl.js
 * author: Rob Blanch
 * usage: rendering logic for Submit Control
 * license: see https://www.stonylanetech.com/licenses/license.txt for details
 * 
 * (C) 2020 Stony Lane Tech LLC
 */
import React from 'react';

/**
 * class: SLTSubmitControl
 * usage: rendering logic for the standard Submit Control on forms in SLT react js applications
 *      note: it expects to render a row within a parent HTML table (ie. <tr></tr> is returned)
 * props:
 *      value - (mandatory) binding to value to display or edit in control
 *      name - (mandatory) name of control in html if you want different than key which is default
 *      onclick - (optional) supply routine to call on touch/click of the submit control
 */
class SLTSubmitControl extends React.Component {
    /**
     * constructor
     */
    constructor(props) {
        super(props);

        var isMissingProps = true;

        /**
         * check to make sure mandatory props have been supplied
         */
        if (props.name !== undefined && props.name !== null && props.name.length > 0 &&
            props.value !== undefined && props.value !== null) {
            isMissingProps = false;
        }

        /**
         * set the initial state
         */
        this.state = {
            isMissingProps: isMissingProps,
        };
    }

    /**
     * render
     *      react js called routine to render the control
     */
    render() {
        if (this.state.isMissingProps === false) {
            var name = this.props.name;
            var value = this.props.value;
            var id = name + "Id";
            var onclick = (this.props.onclick) ? this.props.onclick : null;
    
            return (
                <tr>
                    <td className="Page_Col">
                        <input className="Submit_Ctl" type="submit" id={id} name={name} value={value} onClick={onclick} />
                    </td>
                </tr>    
            );    
        } else {
            return(
                <tr>
                    <td className="Page_Col">
                        <p>SLTSubmitControl missing mandatory props. Check rendered SLTSubmitControl.</p>
                    </td>
                </tr>
            );
        }
    }
}

export default SLTSubmitControl;