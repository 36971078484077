/**
 * module: SLTHeaderNavBar.js
 * author: Rob Blanch
 * created: 4/28/2024
 * license: see https://www.stonylanetech.com/licenses/eigengrau/license.txt
 * 
 * (c) 2024 Stony Lane Tech LLC
 */
import React from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import './SLTHeaderNavBar.css';

/**
 * SLTHeaderNavBar
 *      React JS component to display a header nav bar for navigation purposes in the UI. Reactively handles both phone/desktop versions of nav.
 * 
 * props to supply:
 *   logoItem: (mandatory) information regarding the logo and how to handle request to home link
 *      link: (mandatory) link to navigate to home page
 *      label: (mandatory) label to provide to user in case of no icon capability
 *      icon: (mandatory) icon to provide for home page on nav bar
 *    navItems: (mandatory) array of nav item entries in left to right order describing items from which to select using structure:
 *      link: (mandatory) link to navigate for the nav item being selected by user
 *      label: (mandatory) text label to display for nav item if no icon src provided
 *      icon: (optional) icon src for icon to display instead of label
 *   isNavBarExpanded: (mandatory) true or false flag where true means nav bar menu is expanded and false it is collapsed
 *   setNavBarExpanded(expandNavBar): callback to parent to set isNavBarExpanded to expandNavBar value
 *   websitename: (optional) web site name to show on nav bar (null if none)
 *   backgroundcolor: (optional) if not supplied then default color will apply. if supplied then nav bar will use background color.
 */
class SLTHeaderNavBar extends React.Component 
{
    /**
     * constructor
     */
    constructor(props) {
        super(props);

        var isMissingProps = true;

        if (props &&
            props.logoItem !== undefined && props.logoItem !== null &&
            props.navItems !== undefined && props.navItems !== null &&
            props.isNavBarExpanded !== undefined && props.isNavBarExpanded !== null &&
            props.setNavBarExpanded !== undefined && props.setNavBarExpanded !== null) {
                isMissingProps = false;
        }
        

        this.state = {
            isMissingProps: isMissingProps,
        }
    }

    /**
     * renderNavItem
     *  return rendering for nav item. parameters mean the following:
     *      link: link to navigate if user selects nav item
     *      label: label to display for nav item if no icon available
     *      icon: icon information or null if no icon to display
     *      onclick: method to call for the onclick event
     */
    renderNavItem(link, label, icon, onclick) {
        if (onclick) {
            if (icon === null || this.props.isNavBarExpanded) {
                return(
                    <li className="sltnavbar-item" key={label}><a className="sltnavbar-link" onClick={onclick} href={link}>{label}</a></li>
                );    
            }
    
            return(
                <li className="sltnavbar-item" key={label}>
                    <img className="sltnavbar-logo" src={icon.default} alt={label} onClick={onclick}/>
                </li>
            );
        } else {
            if (icon === null || this.props.isNavBarExpanded) {
                return(
                    <li className="sltnavbar-item" key={label}><a className="sltnavbar-link" href={link}>{label}</a></li>
                );    
            }
    
            return(
                <li className="sltnavbar-item" key={label}>
                    <Link to={link} id={label}>
                        <img className="sltnavbar-logo" src={icon.default} alt={label}/>
                    </Link>                
                </li>
            );   
        }
    }

    /**
     * renderNavMenu
     *  return the menu control with nav items properly listed within it
     *      navItems: array of structures with details on navigational nav items to show to user
     *          link: link to navigate for action of nav item
     *          label: label to display for nav item on menu
     *          icon: icon to display instead of label
     *          onclick: routine to call when item has onClick event
     */
    renderNavMenu(navItems) {

        if (navItems !== undefined && navItems !== null && "length" in navItems) {
            var navMenuItemElements = [];

            /**
             * get list of navbar-item links to represent the navItems
             */
            for (var idxNavItem=0; idxNavItem < navItems.length; idxNavItem++) {
                var navItem = navItems[idxNavItem];
                var link = ("link" in navItem) ? navItem.link : "[[missing link]]";
                var label = ("label" in navItem) ? navItem.label : "[[mising label]]";
                var icon = ("icon" in navItem) ? navItem.icon : null;
                var onclick = ("onclick" in navItem) ? navItem.onclick : null;
                var navItemElement = this.renderNavItem(link, label, icon, onclick);
                if (navItemElement !== null) {
                    navMenuItemElements.push(navItemElement);
                }    
            }

            if (navMenuItemElements.length > 0) {
                return(
                    <div id="sltnavbar-menu" aria-labelledby="sltnavbar-toggle">
                        <ul className="sltnavbar-links">
                            {navMenuItemElements}
                        </ul>
                    </div>
                );
            }
        }

        return(null);
    }

    /**
     * renderNavHomeLink
     *  return the HTML for the logo display and go to home page action
     *      logoItem: structure representing the logo/home information
     *          link: link to handle navigation to the home
     *          label: label to display for logo
     *          icon: icon information to display a logo
     *      websitename: name to display for website (null if no display)
     */
    renderNavHomeLink(logoItem, websitename) {
        if (logoItem !== undefined && logoItem !== null) {
            var link = ("link" in logoItem) ? logoItem.link : "[[missing logo link]]";
            var label = ("label" in logoItem) ? logoItem.label : "[[missing logo label]]";
            var icon = ("icon" in logoItem) ? logoItem.icon : "[[missing logo icon]]";

            return(
                <a href={link} className ="slthome-link">
                    <div className="sltnavbar-logo">
                        <img className="sltnavbar-logo" src={icon.default} alt={label}/>
                    </div>
                    {(websitename) ? websitename : ""}
                </a>
            );
        }

        return(null);
    }

    /**
     * setNavBarExpanded
     *  called via onClick of toggle menu control
     */
    setNavBarExpanded() {
        var newExpandState = (this.props.isNavBarExpanded) ? false : true;
        this.props.setNavBarExpanded(newExpandState);
    }

    /**
     * forceCloseNavBar
     *  called to close the nav bar when user clicks outside of the menu of the open nav bar
     */
    forceCloseNavBar() {
        if (this.props.isNavBarExpanded) {
            this.props.setNavBarExpanded(false);
        }
    }

    /**
     * renderNavBarToggleButton
     *  render the toggle button used for nav bar
     */
    renderNavBarToggleButton(isNavBarExpanded) {
        var ariaexpandstate = (isNavBarExpanded) ? "true" : "false";
        var setNavBarExpanded = this.setNavBarExpanded.bind(this);

        return(
            <button
                type="button"
                id="sltnavbar-toggle"
                aria-controls="stlnavbar-menu"
                aria-label="Toggle menu"
                aria-expanded={ariaexpandstate}
                onClick={setNavBarExpanded}
                >
                    <span className="slticon-bar" key="barone"></span>
                    <span className="slticon-bar" key="bartwo"></span>
                    <span className="slticon-bar" key="barthree"></span>
                </button>
        );
    }

    /**
     * renderNavBarHeader
     *  render the complete nav bar header
     *      logoItem: information to display logo
     *      websitename: name to provide for web site name (optional, null if none)
     *      navItems: information to display menu options in nav bar
     */
    renderNavBarHeader(logoItem, websitename, navItems, isNavBarExpanded, backgroundcolor) {
        var forceCloseNavBar = this.forceCloseNavBar.bind(this);

        return(
            <header id="sltnavbar" style={{backgroundColor:{backgroundcolor}}}>
                <nav className="sltnavbar-container sltcontainer" onClick={forceCloseNavBar}>
                    {this.renderNavHomeLink(logoItem, websitename)}
                    {this.renderNavBarToggleButton(isNavBarExpanded)}
                    {this.renderNavMenu(navItems)}
                </nav>
            </header>
        );
    }

    /**
     * render
     *  react js call to render UI components
     */
    render() {
        if (this.state.isMissingProps === false) {
            return(this.renderNavBarHeader( this.props.logoItem, 
                this.props.websitename, 
                this.props.navItems, 
                this.props.isNavBarExpanded,
                this.props.backgroundcolor));
        } else {
            return(
                <p>
                    Mising props to display the SLTHeaderNavBar correctly. Must have: logoItem, navItems, isNavBarExpanded, setNavBarExpanded.
                </p>
            );
        }
    }
}

export default withRouter(SLTHeaderNavBar);