/**
 * module: TabBar.js
 * author: Rob Blanch
 * usage: provide tab bar for slt application
 * 
 * license: see https://www.stonylanetech.com/licenses/license.txt for details
 * 
 * (C) 2019 Stony Lane Tech LLC
 */
import React from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

/**
 * TabBar
 *      React JS component to display a tab bar rendering in the UI. following are props:
 * 
 *  tabs: (mandatory) array of tab entries in left to right tab order describing tab using the structure of:
 *      link: (mandatory) link to navigate for the tab being selected by user
 *      label: (mandatory) text label to display on tab bar if no icon src provided in props or icon src not available
 *      iconSelected: (optional) src location for icon to show on tab of tab bar when it is selected state
 *      iconNotSelected: (optional) src location for icon to show on tab of tab bar when tab is NOT selected state
 *      onDoubleTap: (optional) callback for double tab action on tab
 */
class TabBar extends React.Component
{
    /**
     * constructor
     */
    constructor(props) {
        super(props);

        this.tapOne = null;
        this.tapOneWhen = null;
    }

    /**
     * isDoubleTapClick
     *  return true double click event, false if not
     */
    isDoubleTapClick(e) {
        var isDoubleTapClick = false;
        var secondTapTime = Date.now();
        const target = (e) ? e.target : null;
        const name = (target) ? target.alt : null;

        if (name && this.tapOne === name) {
            if (this.tapOneWhen) {
                var firstTapTime = this.tapOneWhen;
                if ((secondTapTime - firstTapTime) < 1000) {
                    isDoubleTapClick = true;
                }
            }
        }

        this.tapOne = name;
        this.tapOneWhen = secondTapTime;

        return isDoubleTapClick;
    }

    /**
     * renderTab
     *  return rendering for a tab. parameters mean the following:
     *      isFocus: true if tab has selected focus and false if not
     *      link: link to navigate if the user has selected the tab
     *      label: label to display for tab if there is no icon available or no icon provided
     *      icon: path to icon source to display on tab
     */
    renderTab(isFocus, link, label, icon, onClick, tabCount) {
        var strwidth = ((tabCount > 1) ? 100/tabCount : 100).toString();

        return (
            <td className="TabBar_Col" id="tabbar-col2" key={"tab_"+label} style={{width: strwidth+'%'}}>
                <span className={(isFocus) ? "TabBarButton_Selected" : "TabBarButton_NotSelected"} >
                    <Link to={link} id={label}>
                        <img className="slt_tabbar_button" src={icon.default} alt={label} onClick={onClick}/>
                    </Link>
                </span>
            </td>
        );
    }

    /**
     * getPath
     *  return the path of the tab bar if it is recognized as valid, null otherwise
     */
    getPath() {
        var path = null;

        if (this.props && this.props.tabs && this.props.tabs.length > 0 && this.props.location && ("pathname" in this.props.location) === true) {
            path = this.props.location.pathname;
            if (path && path.length > 0) {
                var tabIdx = 0;
                for ( ; tabIdx < this.props.tabs.length; tabIdx++) {
                    var tab = this.props.tabs[tabIdx];
                    if (tab && ("link" in tab) === true && tab.link === path) {
                        break;
                    }
                }

                if (tabIdx >= this.props.tabs.length) {
                    path = null;
                }
            }
        }

        return path;
    }

    /**
     * onClick
     *  used to process double tap events for tab bar where a callback provided
     * to user of tab bar can reset state to bring the user to a home page
     * for the tab section of the application. The onClick measures how quickly
     * the user presses the tab bar twice to determine if it is a double tap
     * scenario.
     */
    onClick(e) {
        if (this.isDoubleTapClick(e)) {
            const target = e.target;
            const label = target.alt;
            var onDoubleTap = null;

            for (var idx=0; idx < this.props.tabs.length; idx++) {
                if (this.props.tabs[idx].label === label) {
                    onDoubleTap = this.props.tabs[idx].onDoubleTap;
                    break;
                }
            }

            if (onDoubleTap) {
                console.log('TabBar.onClick() [INFO]: double tab detected and calling onDoubleTap() for label=' + label);
                onDoubleTap(label);
            }
        }
    }

    /**
     * renderTabs
     *  render each of the tabs based on props information provided to the class
     * note: this routine assumes path is a valid path matching metadata of
     *  an entry in the props.tabs for this object.
     */
    renderTabs(path) {
        var renderedTabs = null;
        var onClick = this.onClick.bind(this);
        var tabCount = this.props.tabs.length;

        for (var idx=0; idx < this.props.tabs.length; idx++) {
            var tabMetaData = this.props.tabs[idx];

            if (tabMetaData) {
                var link = tabMetaData.link;
                var label = tabMetaData.label;
                var isFocus = (path === link) ? true : false;
                var icon = (isFocus) ? tabMetaData.iconSelected : tabMetaData.iconNotSelected;

                var tab = this.renderTab(isFocus, link, label, icon, onClick, tabCount);
                if (tab) {
                    if (renderedTabs) {
                        renderedTabs.push(tab);
                    } else {
                        renderedTabs = [ tab ];
                    }
                }
            }
        }

        return renderedTabs;
    }

    /**
     * renderTabBar
     *  Renders tab bar UI controls within table
     */
    renderTabBar() {
        var path = this.getPath();

        if (path) {
            return (
                <div id="SLT-TabBar">
                    <table className="TabBar_Table" id="tabbar-table" colSpan="3">
                        <tbody>
                            <tr>
                                {this.renderTabs(path)}
                            </tr>
                        </tbody>
                    </table>
                </div>
            );    
        } else {
            /**
             * don't show the tab bar if not a recognized path based
             * on supplied tab metadata in props
             */
            return null;
        }
    }

    /**
     * render
     *  react js call to render UI components
     */
    render() {
        return(this.renderTabBar());
/**        var setNewFocus = this.setNewFocus.bind(this);

        return (    
            <Switch>
                <Route path='/test' render={(props) => <TabBarLayout focus={'test'} priorFocus={this.currentTab} setNewFocus={setNewFocus}/>}/>
                <Route path='/runs' render={(props) => <TabBarLayout focus={'runs'} />}/>
                <Route path='/account' render={(props) => <TabBarLayout focus={'account'}/>} />
            </Switch>
        );
    }    
**/
    }
}

export default withRouter(TabBar);