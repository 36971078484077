/**
 * module: ResetForm.js
 * author: Rob Blanch
 * usage: rendering logic for Eigengrau Admin reset password account form
 * license: see https://www.stonylanetech.com/licenses/Eigengrau/license.txt for details
 * 
 * (C) 2020 Stony Lane Tech LLC
 */
import React from 'react';
import {withRouter} from 'react-router';
import SLTHeader from './SLTHeader.js';
import FormMessage from './FormMessage.js';

const SLTRtrQryParamHelper = require('./SLTRtrQryParamHelper.js');
const SLTEigengrauAppErrCodes = require('./SLTEigengrauAppErrCodes.js').SLTEigengrauAppErrCodes;

/**
 * class: ResetForm
 * usage: form used to reset a password for a Eigengrau Admin account
 * props:
 *      logo - image to show in header of form
 *      resetPassword(onComplete, onError, identifier, resetKey, newPassword) - callback for UI to perform a Eigengrau Admin account password reset via Eigengrau service
 */
class ResetForm extends React.Component {
    constructor(props) {
        super(props);

        try {
            var SLTRtrQryHelp = new SLTRtrQryParamHelper();
            var params = SLTRtrQryHelp.getParams(props, "reset", ["identifier","resetkey"], SLTEigengrauAppErrCodes.APPERR_MISSINGPARAM);
            var decode_identifier = params.identifier;
            var decode_resetkey = params.resetkey;    

            this.state = {
                message: null,
                identifier: decode_identifier,
                resetKey: decode_resetkey,
                pwd: '',
            };
        } catch(err) {
            this.state = {
                message: err.message,
                identifier: '',
                resetKey: '',
                pwd: '',
            };
        }

        this.handleChange = this.handleInputChange.bind(this);
        this.handleReset = this.handleReset.bind(this);        
    }

    /**
     * handleInputChange
     *      logic to handle edit text control changes made by user
     */
    handleInputChange(event)
    {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    /**
     * handleReset
     *      logic to perform reset password for a Eigengrau Admin user account
     */
    handleReset(event)
    {
        this.props.resetPassword(
            (success) => {
                this.setState({message: this.state.identifier + ' account password has been reset!'});
            },
            (error) => {
                this.setState({message: JSON.stringify(error)});
            },
            this.state.identifier,
            this.state.resetKey,
            this.state.pwd,
        );

        event.preventDefault();
    }

    /**
     * render
     *      react js call to layout UI
     */
    render() {
        const message = this.state.message;

        return(
            <React.StrictMode>
            <SLTHeader props logo={this.props.logo} rightmenuitem={{label:'Sign In',link:'/'}} />
            <form onSubmit={this.handleReset}>
                <table className="Page_Table" name="ResetTable">
                    <tbody>
                    <tr>
                        <td>
                            <FormMessage message={message} />
                        </td>
                    </tr>
                    <tr>
                        <td className="Page_Col">
                        <input className="Edit_Ctl" type="Password" name="pwd" id="pwdFieldId" placeholder="New Password" maxLength="255" value={this.state.pwd} onChange={this.handleChange} />
                        </td>
                    </tr>
                    <tr>
                        <td className="Page_Col">
                            <input className="Submit_Ctl" type="submit" id="resetButton" value="Reset Password" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
            </React.StrictMode>
        );
    }
}  

export default withRouter(ResetForm);