/**
 * module : SLTEigengrauApp.js
 * author: Rob Blanch
 * usage: main application file for UI component of the Stony Lane Tech main web site
 * license: see https://www.stonylanetech.com/licenses/eigengrau/license.txt for details
 * 
 * (c) 2024 Stony Lane Tech LLC
 */
import React, { Component } from 'react';
import SLTEigengrauLogo from './slt_stonelogo_banner/slt_stonelogo_banner.png';
import './SLTEigengrauApp.css';
import './SelectionTable.css';
import { Switch, Route } from 'react-router-dom';
import SLTEigen_api from './SLTEigengrau_api';
import SLTFooter from './SLTFooter.js';
import TabBar from './TabBar.js';
import LoginForm from './LoginForm.js';
import RegisterForm from './RegisterForm.js';
import ValidationForm from './ValidationForm.js';
import ResetForm from './ResetForm.js';
import RemoveForm from './RemoveForm.js';
import LogoutForm from './LogoutForm.js';
import Version from './Version.js';
import AppPost from './AppPost.js';
import UsageStatistics from './UsageStatistics.js';
import sltDateTime from './sltDateTime';
import SLTEigengrau_api from './SLTEigengrau_api';
import SLTEigengrauAccountTab from './SLTEigengrauAccountTab';
import SLTHomeEigengrau from './SLTHomeEigengrau';

const EigengrauCopyright = ' 2024 Stony Lane Tech LLC';

/**
 * SLT Eigengrau App class which drives page layout for Stony Lane Tech main application
 */
class SLTEigengrauApp extends Component {
  /**
   * constructor for SLTEigengrauApp
   */
  constructor(props) {
    super(props);

    this.sltDateTime = new sltDateTime();

    this.state = {
      message: '',
      logo: SLTEigengrauLogo,
      slteigenapi: new SLTEigengrau_api(),
      isMounted: false,
      isNavBarExpanded: false,
    }

    /**
     * used to reference actions to be supplied
     */
    this.actionHandler = this.actionHandler();

    /**
     * setup session event listeners
     */
    this.state.slteigenapi.registerListener('session-logon', this.loginSessionEvent.bind(this));
    this.state.slteigenapi.registerListener('session-refresh', this.refreshSessionEvent.bind(this));
    this.state.slteigenapi.registerListener('session-logout', this.logoutSessionEvent.bind(this));

    /**
     * if session exists
     */
    this.state.slteigenapi.getSession(
      (response) => {
      },
      (error) => {
        console.log('SLTEigengrauApp::constructor() [WARN]: session load failed. probably not logged in yet.');
      },
      null, null);
  }

  /**
   * setMessage
   *  set the message for user to see
   */
  setMessage(message) {
    this.setState({message: message});
  }

  /**
   * loginSessionEvent
   *  called by slteigenapi when user logs into a new session
   */
  loginSessionEvent(session) {
    console.log('SLTEigengrauApp::loginSessionEvent [INFO]: session login detected.');
  }

   /**
   * refreshSessionEvent
   *  called by slteigenapi when refresh of session occurs with service
   */
  refreshSessionEvent(newSession, oldSession) {
    /**
     * nothing to do for now, but log to console for info purposes
     */
    console.log('SLTEigengrauApp::refreshSessionEvent[INFO]: session refresh occurred');
  }

  /**
   * logoutSessionEvent
   *  called by slteigenapi when user logs out of session
   */
  logoutSessionEvent(session) {
    console.log('SLTEigengrauApp:logoutSessionEvent [INFO]: session logout detected.');
    this.setState({});
  }

  /**
   * getUserProperties
   *  retrieve all of the user properties for session user. onComplete(user_properties)
   * will be called on success.
   */
  getUserProperties(onComplete) {
    try {
      this.state.slteigenapi.getUser(
        (response) => {
          var user_properties = (response.user_properties) ? response.user_properties : null;
          onComplete(user_properties);
        },
        (error) => {
          this.state.message.set(error.message);
          console.log('SLTEigengrauApp::getUserProperties() [ERROR]: Exception encountered. error.message=' + error.message);
        }
      );
    } catch (err) {
      console.log('SLTEigengrauApp::getUserProperties() [FATAL ERROR]: Exception thrown during load user properties from service. err=' + JSON.stringify(err));
    }
  }

  /**
   * setUserProperties
   *  set or put user properties to user of session based on param flag. onComplete(response) will be called on success.
   */
  setUserProperties(should_put_properties, user_properties, onComplete) {
    try {
      this.state.slteigenapi.persistUserProperties(
        (response) => {
          onComplete(response);
        },
        (error) => {
          this.state.message.set(error.message);
          console.log('SLTEigengrauApp::setUserProperties() [ERROR]: Exception encountered. error.message=' + error.message);
        },
        should_put_properties,
        user_properties
      );
    } catch (err) {
      console.log('SLTEigengrauApp::setUserProperties() [FATAL ERROR]: Exception thrown during setting user properties to service. err=' + JSON.stringify(err));
    }
  }

  /**
   * actionHandler
   *  called to perform action within the application based on event
   */
  actionHandler() {
    var handler = {};

    /**
     * TODO (ROB 4/15/19): for now i'm flattening all actions at root level for the application,
     *  but really should move things down appropriately for improved design for maintenance of application
     */

    /** global error handler so things can be reported anywhere **/
    handler.errorHandler = this.errorHandler.bind(this);

    /**  get usage statistics for service **/
    handler.getUsageStatistics = this.getUsageStatistics.bind(this);

    /**
     * get/set user properties
     */
    handler.getUserProperties = this.getUserProperties.bind(this);
    handler.setUserProperties = this.setUserProperties.bind(this);
    
    return handler;
  }

  /**
   * getUsageStatistics
   *  handler for obtaining usage statistics of service
   */
  getUsageStatistics(onComplete, onError) {
    this.state.slteigenapi.getUsageStatistics(
      (response) => {
        onComplete(response);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /**
   * errorHandler
   *  error handling routine for the application
   */
  errorHandler(sltEigengrauErrCode, strErrMessage) {
    console.log(" [SLTEigengrauErrCode: " + sltEigengrauErrCode + "] - " + strErrMessage);
    this.setMessage(strErrMessage);
  }

  /**
   * setNavBarExpanded
   *  callback routine to set a new expanded state for the header nav bar menu
   * 
   * newExpandState is true to expand the nav bar menu and false to not.
   */
  setNavBarExpanded(newExpandState) {
    this.setState({isNavBarExpanded: newExpandState});
  }

  /**
   * renderPageDetails
   *  Renders the page details based on user location in the application
   */
  renderPageDetails() {
    const homeIcon = require('./slt_home_icon/slt_home_icon.png');
    const loginIcon = require('./slt_login_icon/slt_login_icon.png');

    const logoItem = {
      link:'/',
      label:'Home',
      icon:homeIcon
    };
    const navItems = [
      {link:'/',label:'Pets',icon:null},
      {link:'/',label:'Cups',icon:null},
      {link:'/',label:'Community',icon:null},
      {link:'/',label:'About',icon:null},
      {link:'/login',label:'Login',icon:loginIcon},
    ];
    var setNavBarExpanded = this.setNavBarExpanded.bind(this);

    return (
      <div className="SLTEigengrauPage">
        <Switch>
          <Route exact path='/' render={(props) => <SLTHomeEigengrau props 
                                                                      logoItem = {logoItem}
                                                                      navItems = {navItems}
                                                                      isNavBarExpanded = {this.state.isNavBarExpanded}
                                                                      setNavBarExpanded = {setNavBarExpanded}
                                                                      websitename = "Stony Lane Tech"
                                                                      backgroundcolor = {"#f3f1f4"}/>
                                        } />
          <Route exact path='/login' render={(props) => <LoginForm   props 
                                                                logoItem = {logoItem} 
                                                                isNavBarExpanded = {this.state.isNavBarExpanded}
                                                                setNavBarExpanded = {setNavBarExpanded}
                                                                getSession = {this.state.slteigenapi.getSession.bind(this.state.slteigenapi)} />} />
          <Route path='/register' render={(props) => <RegisterForm  props 
                                                                    logoItem = {logoItem} 
                                                                    isNavBarExpanded = {this.state.isNavBarExpanded}
                                                                    setNavBarExpanded = {setNavBarExpanded}
                                                                    createUser={this.state.slteigenapi.createUser.bind(this.state.slteigenapi)}
                                                                    DefaultAlias={SLTEigen_api.DefaultAlias} />}/>
          <Route path='/validate' render={(props) => <ValidationForm    props 
                                                                        logo={this.state.logo}
                                                                        validateAccount={this.state.slteigenapi.validateAccount.bind(this.state.slteigenapi)} />}/>
          <Route path='/reset' render={(props) => <ResetForm    props 
                                                                logo={this.state.logo}
                                                                resetPassword={this.state.slteigenapi.resetPassword.bind(this.state.slteigenapi)} />} />
          <Route path='/remove' render={(props) => <RemoveForm  props 
                                                                logo={this.state.logo}
                                                                removeAccount={this.state.slteigenapi.removeAccount.bind(this.state.slteigenapi)} />} />
          <Route path='/logout' render={(props) => <LogoutForm  props 
                                                                logoItem = {logoItem} 
                                                                isNavBarExpanded = {this.state.isNavBarExpanded}
                                                                setNavBarExpanded = {setNavBarExpanded}
                                                                deleteSession={this.state.slteigenapi.deleteSession.bind(this.state.slteigenapi)} />} />
          <Route path='/version' render={(props) => <Version  props
                                                              logoItem = {logoItem} 
                                                              isNavBarExpanded = {this.state.isNavBarExpanded}
                                                              setNavBarExpanded = {setNavBarExpanded}
                                                              getVersion={this.state.slteigenapi.getVersion.bind(this.state.slteigenapi)} />} />
          <Route path='/app_post' render={(props) => <AppPost props
                                                              logo={this.state.logo}
                                                              postApplication={this.state.slteigenapi.postApplication.bind(this.state.slteigenapi)} />} />
          <Route path='/usagestats' render={(props) => <UsageStatistics props 
                                                                        logo={this.state.logo} 
                                                                        getUsageStatistics={this.state.slteigenapi.getUsageStatistics.bind(this.state.slteigenapi)}/>} />
          {/** PLACEHOLDER FOR SERVICE LIST FOR NOW **/}
          <Route path='/servicelist' render={(props) => <SLTEigengrauAccountTab props 
                                                                        logo={this.state.logo} 
                                                                        getUser={this.state.slteigenapi.getUser.bind(this.state.slteigenapi)}
                                                                        updateUser={this.state.slteigenapi.updateUser.bind(this.state.slteigenapi)} />} />
          {/** PLACEHOLDER FOR SERVICE FOR NOW **/}
          <Route path='/service' render={(props) => <SLTEigengrauAccountTab props 
                                                                        logo={this.state.logo} 
                                                                        getUser={this.state.slteigenapi.getUser.bind(this.state.slteigenapi)}
                                                                        updateUser={this.state.slteigenapi.updateUser.bind(this.state.slteigenapi)} />} />
          <Route path='/account' render={(props) => <SLTEigengrauAccountTab props 
                                                                        logoItem = {logoItem} 
                                                                        isNavBarExpanded = {this.state.isNavBarExpanded}
                                                                        setNavBarExpanded = {setNavBarExpanded}
                                                                        getUser={this.state.slteigenapi.getUser.bind(this.state.slteigenapi)}
                                                                        updateUser={this.state.slteigenapi.updateUser.bind(this.state.slteigenapi)} />} />
        </Switch>
      </div>);
  }

  /**
   * onTabBarDoubleTap
   *  callback for handling reseting page to "home" page based on double tap of tab bar selection
   * parameter for label used of tabbar is provided for figuring out which tab bar was double tapped
   */
  onTabBarDoubleTap(label) {
    /** we don't care -- do nothing **/
  }

  /**
   * renderTabBar
   *  Renders tab bar on all SLT Orange pages
   */
  renderTabBar() {
    const servicelistIconNotSelected = require('./sltegn_servicelist_icon_notselected/sltegn_servicelist_icon_notselected.png');
    const servicelistIconSelected = require('./sltegn_servicelist_icon_selected/sltegn_servicelist_icon_selected.png');
    const serviceIconSelected = require('./sltegn_service_icon_selected/sltegn_service_icon_selected.png');
    const serviceIconNotSelected = require('./sltegn_service_icon_notselected/sltegn_service_icon_notselected.png');
    const accountIconNotSelected = require('./slt_account_icon_notselected/slt_account_icon_notselected.png');
    const accountIconSelected = require('./slt_account_icon_selected/slt_account_icon_selected.png');
    var onTabBarDoubleTap = this.onTabBarDoubleTap.bind(this);

    var tabs = [
      {"link": '/servicelist', "label": 'service list', "iconSelected": servicelistIconSelected, "iconNotSelected": servicelistIconNotSelected, "onDoubleTap": onTabBarDoubleTap},
      {"link": '/service', "label": 'service', "iconSelected": serviceIconSelected, "iconNotSelected": serviceIconNotSelected, "onDoubleTap": onTabBarDoubleTap},
      {"link": '/account', "label": 'account', "iconSelected": accountIconSelected, "iconNotSelected": accountIconNotSelected, "onDoubleTap": onTabBarDoubleTap}
    ];

    return (
      <TabBar props tabs={tabs}/>
    );
  }

  /**
   * renderFooter
   *  Renders footer on all SLT Eigengrau pages
   */
  renderFooter() {
    return (
      <SLTFooter
        props
        copyright={EigengrauCopyright}>
      </SLTFooter>
    );
  }

  /**
   * componentDidMount
   *  reactjs call to indicate component has been mounted which is important since things like setState can not be used prior to mounting
   */
  componentDidMount() {
    var state = {isMounted: true};
    this.setState({state});
  }

  /**
   * render
   *  main reactjs call for using JSX to represent UI layout in app element life cycle
   */
  render() {
    return (
      <div className="slt_background">
        <div className="slt_background_overlay">
          <div className="SLTEigengrauApp">
            {this.renderPageDetails()}

            {this.renderTabBar()}

            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }
} 

export default SLTEigengrauApp;
