/**
 * module: sltDateTime.js
 * author: Rob Blanch
 * created: 2/1/2018
 * license: see https://www.stonylanetech.com/licenses/license.txt for details
 * 
 * (c) 2018 Stony Lane Tech LLC
 */
var datetime_lib = require('date-and-time');

const formats = [ "YYYY-MM-DD HH:mm:ss",       /** default Stony Lane Tech date time format is index zero **/
                "YYYY/MM/DD HH:mm:ss",
                "YYYY-MM-DDTHH:mm:ss",
                "YYYY/MM/DDTHH:mm:ss", 
                "YYYY-MM-DDThh:mm:ss A",
                "YYYY/MM/DDThh:mm:ss A",
                "YYYY-MM-DD h:m:s A",
                "YYYY/MM/DD h:m:s A",
                "YYYY-MM-DDTh:m:s A",
                "YYYY/MM/DDTh:m:s A",
                "YYYY-MM-DD h:mm:ss A",
                "YYYY/MM/DD h:mm:ss A",
                "YYYY-MM-DD h:m:ss A",
                "YYYY/MM/DD h:m:ss A",
                "YYYY-M-D HH:mm:ss",
                "YYYY/M/D HH:mm:ss",
                "YYYY-MM-DD H:mm:ss",
                "YYYY/MM/DD H:mm:ss",
                "YYYY-MM-DD hh:mm:ss A",
                "YYYY/MM/DD hh:mm:ss A",
                "YYYY-M-DTh:m:s A",
                "YYYY/M/DTh:m:s A",
                "YYYY-MM-DD", 
                "YYYY/MM/DD", 
                "YYYY-M-D",
                "YYYY/M/D",
                "MM-DD-YYYY HH:mm:ss", 
                "MM/DD/YYYY HH:mm:ss",
                "MM-DD-YYYY H:mm:ss",
                "MM/DD/YYYY H:mm:ss",
                "MM-DD-YYYY hh:mm:ss A",
                "MM/DD/YYYY hh:mm:ss A",
                "MM-DD-YYYY h:mm:ss A",
                "MM/DD/YYYY h:mm:ss A",
                "MM-DD-YYYY h:m:ss A",
                "MM/DD/YYYY h:m:ss A",
                "MM-DD-YYYY h:m:s A",
                "MM/DD/YYYY h:m:s A",
                "MMM D YYYY HH:mm:ss",
                "DD MMM YYYY HH:mm:ss"];

class sltDateTime {
    /**
     * stripExcess
     *  utility to remove extra ending tag describing Timezone in date time string
     */
    stripExtra(strDateTime) {
        var ret = null;

        if (strDateTime !== undefined && strDateTime !== null && typeof(strDateTime) === "string") {
            var noSpaces = strDateTime.trim();
        
            /** Remove excess TZ label of '(PDT)' **/
            if (noSpaces.length > 15 && noSpaces[noSpaces.length-5] === '(' && noSpaces[noSpaces.length-1] === ')') {
                ret = noSpaces.substring(0, noSpaces.length-15);
            } else {
                ret = noSpaces;
            }
    
            /** Remove possibly leading weekday string **/
            if (ret.length > 4) {
                var strCheckWeekday = ret.substring(0, 3).toLowerCase();
                if (strCheckWeekday === 'sun' || 
                    strCheckWeekday === 'mon' ||
                    strCheckWeekday === 'tue' ||
                    strCheckWeekday === 'wed' ||
                    strCheckWeekday === 'thu' ||
                    strCheckWeekday === 'fri' ||
                    strCheckWeekday === 'sat') {
                        ret = ret.substring(4, ret.length);
                    }
                ret = ret.trim();
            }    
        }

        return ret;
    }

    /**
     * utcNow
     *  return utcNow as date, since JS for some reason doesn't make this easy
     */
    utcNow() {
        return Date.parse(new Date().toUTCString());
    }

    /**
     * isValid
     *  returns true if valid datetime format for Stony Lane Tech services
     */
    isValid(strDateTime) {
        var fRet = false;
        var stripStrDateTime = this.stripExtra(strDateTime);

        for (var idx = 0; idx < formats.length && fRet === false; idx++) {
            try {
                if (datetime_lib.isValid(stripStrDateTime, formats[idx])) {
                    fRet = true;
                }    
            } catch (error) {
                console.log('sltDateTime.isValid WARNING: exception encountered [idx = ' + idx + ']: ' + error.message);
            }
        }

        return fRet;
    }

    /**
     * parse
     *  returns parsed and converted date from datetime string. null
     * returned if no parsing occurs to a date.
     */
    parse(strDateTime, asUtc=true) {
        var date = null;
        var fParsed = false;
        var stripStrDateTime = this.stripExtra(strDateTime);

        if (stripStrDateTime && stripStrDateTime.length > 0) {
            for (var idx = 0; idx < formats.length && fParsed === false; idx++) {
                try {
                    date = datetime_lib.parse(stripStrDateTime, formats[idx], asUtc);
                    if (date) {
                        fParsed = true;
                    }    
                } catch (error) {
                    console.log('sltDateTime.parse WARNING: exception encountered [idx = ' + idx + ']: ' + error.message);
                }
            }    
        }

        return date;
    }

    /**
     * db_datetime_string
     *  returns a date & time string valid for use in call to database (ie. stored proc)
     * and will return null if date & time string supplied is not understandable. this
     * is the helper routine to use for handling api provided date & time strings and
     * quickly translate to a consistent string format for submission to db.
     * 
     * note: default is all date time strings are assumed UTC, but may be
     * used to convert local string into db UTC date time string by setting fUTC
     * to false.
     */
    db_datetime_string(strApiDateTime, parseAsUTC=true) {
        var dbDateTimeStr = null;
        var datetime = this.parse(strApiDateTime, parseAsUTC);

        if (datetime) {
            dbDateTimeStr = this.toString(datetime);
        }

        return dbDateTimeStr;
    }

    /**
     * toString
     *  returns offical formatted Stony Lane Tech string for date which is always first format in array
     */
    toString(date, convertToUTC=true) {
        return datetime_lib.format(date, formats[0], convertToUTC);
    }

    /**
     * toShortString
     *  returns shortened format for date/time
     */
    toShortString(date, convertToUTC=true) {
        return datetime_lib.format(date, "M/D H:m:s", convertToUTC);
    }

    /**
     * supported
     *  returns supported datetime formats for Stony Lane Tech strings to be converted to date
     */
    supported() {
        var message = null;

        for (var idx = 0; idx < formats.length; idx++) {
            if (message) {
                message = message + ", " + formats[idx];
            } else {
                message = formats[idx];
            }
        }

        return message;
    }

    /**
     * compareDateTimes
     *  used to compare two date & time variables which are
     * of type JS Date. note nulls will be handled. return value
     * will be:
     *      -1 for a < b || (a === null && b !== null), 
     *      zero for a === b || (a === null && b === null), 
     *      1 for a > b || (a !== null && b === null).
     * 
     * note: undefined or non-Date values will be treated as null.
     */
    compareDateTimes(a, b) {

        /** default undefined to null for analysis **/
        if (a === undefined || isNaN(a) || (a instanceof Date) === false) {
            a = null;
        }

        if (b === undefined || isNaN(b) || (b instanceof Date) === false) {
            b = null;
        }

        /**
         * handle null value cases
         */
        if (a === null && b === null) {
            return 0;
        } else if (a === null && b !== null) {
            return -1;
        } else if (a !== null && b === null) {
            return 1;
        }

        /**
         * now compare the date/time values of a & b
         */
        var tma = a.getTime();
        var tmb = b.getTime();

        if (tma === tmb) {
            return 0;
        } else if (tma < tmb) {
            return -1;
        } else {
            return 1;
        }
    }
}

export default sltDateTime;