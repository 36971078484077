/**
 ** module: UsageStatistics.js
 ** usage: shows service usage statistics for application
 ** created: 5/15/2019
 ** owner: Rob Blanch
 **/
import React from 'react';
import FormMessage from './FormMessage.js';
import SLTHeader from './SLTHeader.js';
import SLTEditControl from './SLTEditControl.js';
import sltDateTime from './sltDateTime.js';

/**
 * usageStatistics
 *  UI logic for displaying usage statistics of service
 * props:
 *      logo - image to show in header of UI
 *      getUsageStatistics(onSuccess, onError) - call service for usage statistics
 */
class UsageStatistics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            error: null,
            isLoaded: false,
            usageStatistics: null,
        };

        this.isPendingLoad = false;

        this.loadUsageStatistics = this.getUsageStatistics.bind(this);
        this.timerTick = this.timerTickHandle.bind(this);
    }

    /**
     * getUsageStatistics
     *  obtain statistics from the service with provided props api
     */
    getUsageStatistics() {
        try {
            this.props.getUsageStatistics(
                (statistics) => {
                    this.setState({usageStatistics: statistics, isLoaded: true});
                    this.isPendingLoad = false;
                },
                (error) => {
                    this.setState({error: error});
                });
        } catch (error) {
            this.setState({error: error});
        }
    }

    /**
     * componentDidMount
     *  react js event to process after UI is mounted
     */
    componentDidMount() {
        this.loadUsageStatistics();

        this.timerID = setInterval(
            () => this.timerTick(),
            10000
        );
    }

    /**
     * componentWillUnmount
     *  called by react js when component will unmount
     */
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    /**
     * timerTickHandle
     *  called when timer of 1 second fires
     */
    timerTickHandle() {
        if (this.isPendingLoad === false) {
            this.isPendingLoad = true;
            this.loadUsageStatistics();
        }
    }

    /**
     * render
     *  react js event for rendering the UI
     */
    render() {
        const { error, isLoaded } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
                const message = this.state.message;
                var usageStatistics = this.state.usageStatistics;
                var dt = new sltDateTime();

                return(
                    <React.StrictMode>
                        <SLTHeader props logo={this.props.logo} rightmenuitem={{label:'Sign In',link:'/'}} />
                        <form onSubmit={this.handleSubmit}>
                            <div className="ProfileHeader">
                                <div className="ProfileHeaderLabel">
                                    Usage Statistics
                                </div>
                            </div>

                            <table className="Page_Table" name="usageStatisticsTable" align="center">
                                <tbody>
                                    <tr>
                                        <td>
                                            <FormMessage message={message}/>
                                        </td>
                                    </tr>

                                    <SLTEditControl props
                                                    key="asof"
                                                    name="asof"
                                                    value={dt.toShortString(dt.parse(usageStatistics.asof))}
                                                    label="As Of (UTC):" />
                                    <SLTEditControl props
                                                    key="active"
                                                    name="active"
                                                    value={usageStatistics.active_sessions_total}
                                                    label="Active Sessions:" />
                                    <SLTEditControl props
                                                    key="expired"
                                                    name="expired"
                                                    value={usageStatistics.expired_sessions_total}
                                                    label="Expired Sessions:" />
                                    <SLTEditControl props
                                                    key="oldest"
                                                    name="oldest"
                                                    value={usageStatistics.oldest_session_in_seconds}
                                                    label="Oldest Session (secs):" />
                                    <SLTEditControl props
                                                    key="oldest_expired"
                                                    name="oldest_expired"
                                                    value={(usageStatistics.oldest_expired_session) ? usageStatistics.oldest_expired_session : 'None'}
                                                    label="Oldest Expired Session (secs):" />
                                    <SLTEditControl props
                                                    key="expirationsin15"
                                                    name="expirationsin15"
                                                    value={usageStatistics.sessions_expire_next_15_min}
                                                    label="Expirations Next 15 Min:" />
                                    <SLTEditControl props
                                                    key="usertotal"
                                                    name="usertotal"
                                                    value={usageStatistics.user_total}
                                                    label="User Total:" />
                                </tbody>
                            </table>
                        </form>
                    </React.StrictMode>
                );    
        }
    }
}  

export default UsageStatistics;