/**
 * SLTEigengrauAppErrCodes
 *      these are error codes specific to the SLT Eigengrau application (not SLT Eigengrau REST APIs)
 */
module.exports = {
    SLTEigengrauAppErrCodes: {
        APPERR_UNKNOWN : 100000,
        APPERR_MISSINGPARAM : 100001,
        APPERR_PROPSMISSING : 100008,
    }
}