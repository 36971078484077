/**
 * module: SLTEigengrauAppErrHandler.js
 * author: Rob Blanch
 * created: 3/26/2020
 * usage: Header UI component of Stony Lane Tech Single Page App
 * license: see https://www.stonylanetech.com/licenses/license.txt for details
 * 
 * (C) 2024 Stony Lane Tech LLC
 */
const SLTEigengrauAppErrCodes = require('./SLTEigengrauAppErrCodes.js').SLTEigengrauAppErrCodes;

const SLTEigengrauAppErrors = [
    {errtype: "EIGENGRAU", errcode: 9, errmsg: "SQL command execution error encountered."},
    {errtype: "PROC", errcode: 10000, errmsg: "Incorrect account identifier or password supplied to access sltorange user account."},
    {errtype: "APPERR", errcode: 100001, errmsg: "Missing parameter in URL to provide sltorange user interface experience."},
];

var apperr_index = null;

/**
 * class: SLTEigengrauAppErrHelper
 * usage: used to help with throwing/providing standard errors in SLT Eigengrau app (not SLT Eigengrau REST API code base)
 */
class SLTEigengrauAppErrHelper {
    /**
     * getDefaultErrCode
     *  returns the set default error code for any errors thrown by SLT Eigengrau Admin application
     */
    getDefaultErrCode() {
        return SLTEigengrauAppErrCodes.APPERR_UNKNOWN;
    }

    /**
     * throwAppErrIfResponseErr
     *      routine to throw err exception with app err message if REST API response is
     * an SLT Service formated error response.
     */
    throwAppErrIfResponseErr(response) {
        if (response && response.errorcode && response.errormessage) {
            this.throwAppErr(response.errorcode, response.errormessage, response.params);
        }
    }

    /**
     * throwAppErr
     *  used to throw an SLTEigengrau application error
     */
    throwAppErr(errCode = null, errMsg = null, params = null) {
        errCode = (errCode) ? errCode : this.getDefaultErrCode();
        errMsg = (errMsg) ? errMsg : this.getErrMsg(errCode, params);
        throw new Error(errMsg);
    }

    /**
     * getErrMsg
     *  return an SLTEigengrau application error message for the provided error code
     */
    getErrMsg(errCode, params = null) {
        var errMsg = "SLT Eigengrau App-Err [" + errCode + "]";

        if (params) {
            var paramkeys = Object.keys(params);
            for (var idx = 0; idx < paramkeys.length; idx++) {
                if (idx === 0) {
                    errMsg = "(";
                } else {
                    errMsg = errMsg + ", ";
                }

                errMsg = errMsg + paramkeys[idx] + " = " + params[paramkeys[idx]];
            }
            errMsg = errMsg + ")";
        }

        /**
         * TODO: Integrate an error message lookup facility, but for now just return the error code while in development
         * 
         * ROB 10/4/2018
         */

        return errMsg;
    }

    /**
     * getUserErrMsgFromError
     *  called to obtain a user presentable error message from an error object
     */
    getUserErrMsgFromError(error) {
        var message = (error && error.message) ? error.message : "SLTEigengrauAppErrHelper::getUserErrMsgFromError(): no error message found";
        var errors_in_message = this.getErrorsInMessage(message);
        var errorsfound = [];

        if (errors_in_message) {
            var msg_type_pattern = /_([A-Z]*)_/;
            var msg_errcode_pattern = /\[([0-9]*)\]/;
            var ranking = null;
            var maxrankingvalue = -1;
            var maxrankingidx = null;

            /**
             * go get information defined for errors and determine one to show based on ranking
             */
            for (var idx = 0; idx < errors_in_message.length; idx++) {
                var msg_type_array = errors_in_message[idx].match(msg_type_pattern);
                var msg_errcode_array = errors_in_message[idx].match(msg_errcode_pattern);

                if (msg_type_array && msg_type_array.length > 0 && msg_errcode_array && msg_errcode_array.length > 0) {
                    var msg_type = null;
                    var msg_errcode = null;

                    msg_type = msg_type_array[1].toUpperCase();
                    msg_errcode = msg_errcode_array[1];

                    if (msg_type && msg_errcode) {
                        var app_err_info = this.getAppErrInfo(msg_type, msg_errcode);
                        if (app_err_info !== undefined && app_err_info !== null) {
                            ranking = this.getAppErrInfoRanking(app_err_info);
                            errorsfound.push(app_err_info);
    
                            if (ranking > maxrankingvalue) {
                                maxrankingidx = errorsfound.length-1;
                            }    
                        }
                    }
                }
            }

            /**
             * select error message to show to user based on ranking index of greatest value
             */
            if (maxrankingidx >= 0 && maxrankingidx < errorsfound.length) {
                message = errorsfound[maxrankingidx].errmsg + " [" + errorsfound[maxrankingidx].errtype + ": " + errorsfound[maxrankingidx].errcode.toString() + "]";
            } else {
                message = "SLTEigengrauAppErrHelper::getUserErrMsgFromError(): no ranked error message found for errors";
            }
        }

        return message;
    }

    /**
     * getErrorsInMessage
     *  use regex expressions to find valid error codes from components in an error string returned from service(s)
     */
    getErrorsInMessage(errorstring) {
        var slterr_tag_pattern = /(SLT_[A-Z]*_ERR \[[0-9]*\])/g;
        var slterrors = errorstring.match(slterr_tag_pattern);
        var sltEigengrauerr_tag_pattern = /(SLTEIGEN_[A-Z]*_[A-Z]* \[[0-9]*\])/g;
        var sltEigengrauerrors = errorstring.match(sltEigengrauerr_tag_pattern);
        var sltEigengrauerr_general_pattern = /\[[0-9]*\]:/g;
        var sltEigengrauerr_general = errorstring.match(sltEigengrauerr_general_pattern);
        var reterrors = null;

        if (slterrors && sltEigengrauerrors) {
            reterrors = slterrors.concat(sltEigengrauerrors);
        } else if (slterrors) {
            reterrors = slterrors;
        } else if (sltEigengrauerrors) {
            reterrors = sltEigengrauerrors;
        } else if (sltEigengrauerr_general) {
            /** loop through and remove '[' and ']:' prefix and postfix from string of each errorcode **/
            for (var idx=0; idx < sltEigengrauerr_general.length; idx++) {
                var errcodestr = sltEigengrauerr_general[idx];
                if (errcodestr && errcodestr.length >= 4) {
                    var starterrcode = 1;
                    var enderrcode = errcodestr.length - 2;
                    var errcode = errcodestr.substring(starterrcode, enderrcode);
    
                    if (reterrors) {
                        reterrors.push(errcode);
                    } else {
                        reterrors = [ errcode ];
                    }    
                }
            }
        }

        return reterrors;
    }

    /**
     * getAppErrInfo
     *      routine to return the app error info record provided type (ie. JS, SLT, etc.) and error code (ie. 10000, etc.)
     */
    getAppErrInfo(type, errcode) {
        var lookup_hash = type + errcode.toString();

        if (apperr_index === null) {
            apperr_index = new Array(SLTEigengrauAppErrors.length);
            for (var idx = 0; idx < SLTEigengrauAppErrors.length; idx++) {
                var apperr_hash = SLTEigengrauAppErrors[idx].errtype + SLTEigengrauAppErrors[idx].errcode.toString();
                apperr_index[apperr_hash] = SLTEigengrauAppErrors[idx];
            }
        }

        return (apperr_index) ? apperr_index[lookup_hash] : null;
    }

    /**
     * getAppErrInfoRanking
     *      return a unique ranking to help figure out which error if set of errors encountered should be presented
     * to user. null returned if error ranking can not be determined.
     */
    getAppErrInfoRanking(errinfo) {
        const rank_reserve_per_type = 500000;
        var ranking = null;
        var rank_type = null;

        if (errinfo) {
            switch (errinfo.errtype) {
                case "Eigengrau":
                    rank_type = 10;
                    break;

                case "PROC":
                    rank_type = 5;
                    break;

                case "APPERR":
                    rank_type = 20;
                    break;
                
                default:
                    rank_type = 0;
            }

            /** TODO - ROB - 10/5/2018: this is overly simplistic and will need a revisit **/
            ranking = (rank_type * rank_reserve_per_type) + errinfo.errcode;
        }

        return ranking;
    }
}

export default SLTEigengrauAppErrHelper;