/**
 * module: sltURLSearchParams.js
 * author: Rob Blanch
 * created: 5/23/2019
 * license: see https://www.stonylanetech.com/licenses/sltorange/license.txt for details
 * 
 * (c) 2019 Stony Lane Tech LLC
 */
require('url');

/**
 * sltURLSearchParams
 *  wrapper for URLSearchParams since there are bugs in MSFT Edge versions.
 * certain versions do not escape certain characters properly in browsers.
 */
class sltURLSearchParams {
    constructor(params) {
        try {
            var urlSearchParams = new URLSearchParams(params);
            if (urlSearchParams) {
                const regExReplace = /[@#$^,<>?{}[\]|]/g;
                var searchStr = urlSearchParams.toString();
                if (searchStr && searchStr.match(regExReplace) !== null) {
                    searchStr = '';
                    for (var key of urlSearchParams.keys()) {
                        var value = (key && key.length > 0) ? urlSearchParams.get(key) : null;
    
                        if (value && typeof(value) === "string") {
                            var str = value;
        
                            if (str && str.length > 0 && str.match(regExReplace) !== null) {
                                const regExRobustReplace = /[@#$^,<>?{}[\]|:&+=% ]/g;
                                str = str.replace(regExRobustReplace,
                                    function replace(match) {
                                        const replaceStr = "@#$^,<>?{}[]|:&+=% "; // note: space is at end due to only + char replacement
                                        const encodeStr = "%40%23%24%5E%2C%3C%3E%3F%7B%7D%5B%5D%7C%3A%26%2B%3D%25+";
        
                                        var returnStr = null;
                                        var idxReplaceChar = replaceStr.indexOf(match);
        
                                        if (idxReplaceChar >= 0 && idxReplaceChar < replaceStr.length) {
                                            var idxStart = idxReplaceChar*3;
                                            var idxEnd = (idxReplaceChar+1)*3;
                                            returnStr = encodeStr.substring(idxStart, idxEnd);
                                        } else {
                                            returnStr = match;
                                            console.log("sltURLSearchParams [ERROR]: match of " + match + " NOT mapped!");
                                        }
        
                                        return returnStr;
                                    });
                            }
                            
                            if (searchStr !== '') {
                                searchStr = searchStr + '&';
                            }

                            searchStr = searchStr + key + '=' + str;
                        }
                    }                        
                }

                this.searchParamsString = searchStr;
            } else {
                this.searchParamsString = null;
            }    
        } catch (error) {
            console.log('sltURLSearchParams::constructor() [ERROR]: Exception caught. error = ' + JSON.stringify(error));
            this.searchParamsString = null;
        }
    }

    /**
     * checkIfSymbolExists
     *  called to return true if the symbol provided exists in string; false otherwise
     */
    checkIfSymbolExists(str, symbol) {
        var isEscaped = false;

        if (str && symbol) {
            if (str.indexOf(symbol) !== -1) {
                isEscaped = true;
            }
        }

        return isEscaped;
    }

    /**
     * getSearchString
     *  return the url query search string for a url to execute against
     */
    getSearchString() {
        return this.searchParamsString;
    }
}

export default sltURLSearchParams;