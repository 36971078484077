/**
 * module: RegisterForm.js
 * author: Rob Blanch
 * usage: rendering logic for Eigengrau Admin register new Eigengrau Admin account form
 * license: see https://www.stonylanetech.com/licenses/Eigengrau/license.txt for details
 * 
 * (C) 2024 Stony Lane Tech LLC
 */
import React from 'react';
import FormMessage from './FormMessage.js';
import SLTHeaderNavBar from './SLTHeaderNavBar.js';
import SLTEditControl from './SLTEditControl.js';
import SLTSubmitControl from './SLTSubmitControl.js';

/**
 * class: RegisterForm
 * usage: used to register new Eigengrau user accounts with the Eigengrau service
 * props:
 *      logoItem - logo information to display in nav header of form
 *      isNavBarExpanded - true/false flag indicating if nav bar menu should be expanded
 *      setNavBarExpanded(newExpandState) - callback to alter state of isNavBarExpanaded to newExpandState value
 *      createUser(onComplete, onError, firstName, lastName, alias, identifier, password) - callback to Eigengrau service to register a new Eigengrau Admin user account
 *      DefaultAlias(firstName, lastName) - callback to figure out the default alias for the user provided first & last names
 */
class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        var isMissingProps = true;

        if (this.props !== undefined && this.props !== null &&
            this.props.logoItem !== undefined && this.props.logoItem !== null &&
            this.props.isNavBarExpanded !== undefined && this.props.isNavBarExpanded !== null &&
            this.props.setNavBarExpanded !== undefined && this.props.setNavBarExpanded !== null &&
            this.props.createUser !== undefined && this.props.createUser !== null &&
            this.props.DefaultAlias !== undefined && this.props.DefaultAlias !== null) {
                isMissingProps = false;
        }

        this.state = {
            isMissingProps: isMissingProps,
            firstName: '',
            lastName: '',
            alias: '',
            doDefaultAlias: true,
            identifier: '',
            pwd: '',
            message: null,
        };

        this.handleChange = this.handleInputChange.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleDefaults = this.handleDefaults.bind(this);
    }

    /**
     * handleInputChange
     *      handle user edits on the register new Eigengrau Admin account form
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name === 'alias') {
            var isDefaultAlias = (value && value !== '') ? false : true;
            this.setState({doDefaultAlias: isDefaultAlias});
        }
    }

    /**
     * handleDefaults
     *      routine to provide defaults for controls on the register new Eigengrau Admin account form
     */
    handleDefaults() {
        if (this.props.DefaultAlias) {
            this.setState({alias: this.props.DefaultAlias(this.state.firstName, this.state.lastName)});
        }
    }

    /**
     * handleRegister
     *      routine used to process data on the new Eigengrau Admin user account form by calling Eigengrau service
     */
    handleRegister(event) {
        try {
            const firstName = this.state.firstName;
            const lastName = this.state.lastName;
            const alias = this.state.alias;
            const identifier = this.state.identifier;
            const password = this.state.pwd;
    
            this.props.createUser(
                (result) => {
                    this.setState({message: 'Register Success: ' + JSON.stringify(result)});
                },
                (error) => {
                    this.setState({message: 'Register Error: ' + JSON.stringify(error)});
                },
                firstName,
                lastName,
                alias,
                identifier,
                password,
            );    
        } catch (err) {
            console.log('RegisterForm::handleRegister() [ERROR]: Exception encountered. err.message=' + err.message);
        }

        event.preventDefault();
    }

    /**
     * render
     *      routine called by react js to layout UI
     */
    render() {
        const message = this.state.message;
        const navItems = [
            {link:'/validate',label:'Validate',icon:null},
            {link:'/login',label:'Login',icon:null}
        ];

        return(
            <React.StrictMode>
            <SLTHeaderNavBar    props
                    logoItem={this.props.logoItem}
                    navItems={navItems}
                    isNavBarExpanded={this.props.isNavBarExpanded}
                    setNavBarExpanded={this.props.setNavBarExpanded} />

            <form onSubmit={this.handleRegister} style={{"paddingTop":"75px"}}>
                <div className="ProfileHeader">
                    <div className="ProfileHeaderLabel">
                        Register New User
                    </div>
                </div>

                <table className="Page_Table" name="registerTable" align="center">
                    <tbody>
                    <tr>
                        <td>
                            <FormMessage message={message}/>
                        </td>
                    </tr>

                    <SLTEditControl props
                                    name="firstName"
                                    key="firstName"
                                    value={this.state.firstName}
                                    onChange={this.handleChange}
                                    onBlur={this.handleDefaults}
                                    maxlength={255}
                                    label="First:" />

                    <SLTEditControl props
                                    name="lastName"
                                    key="lastName"
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                    onBlur={this.handleDefaults}
                                    maxlength={255}
                                    label="Last:" />

                    <SLTEditControl props
                                    name="alias"
                                    key="alias"
                                    value={this.state.alias}
                                    onChange={this.handleChange}
                                    maxlength={255}
                                    label="Alias:" />

                    <SLTEditControl props
                                    name="identifier"
                                    key="identifier"
                                    value={this.state.identifier}
                                    onChange={this.handleChange}
                                    maxlength={255}
                                    label="Email:" />

                    <SLTEditControl props
                                    name="pwd"
                                    key="pwd"
                                    value={this.state.pwd}
                                    onChange={this.handleChange}
                                    maxlength={255}
                                    label="Password:" />

                    <SLTSubmitControl   props
                                        name="register"
                                        key="register"
                                        value="Register" />
                    </tbody>
                </table>
            </form>
            </React.StrictMode>
        );
    }
}  

export default RegisterForm;