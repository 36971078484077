/**
 ** module: AppPost.js
 ** usage: to create SLT Eigengrau back-end service via application POST
 ** created: 4/4/2019
 ** owner: Rob Blanch
 **/
import React from 'react';
import FormMessage from './FormMessage.js';
import SLTHeader from './SLTHeader.js';

/**
 * class: AppPost
 * usage: used to execute POST Application REST API to construct a new instance of Eigengrau application on service
 * props:
 *      logo - reference to logo to show in header of form
 *      postApplication(onComplete, onError, registrationKey, owner, label) - callback routine to execute a REST API to build out a new Eigengrau service instance
 */
class AppPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            message: '',
            registrationKey: '',
            owner: '',
            label: '',
        };

        this.handleChange = this.handleInputChange.bind(this);
        this.handleAppPost = this.handleAppPost.bind(this);
    }

    /**
     * componentDidMount
     *      called routine by react js upon the UI component being loaded into DOM
     */
    componentDidMount() {
    }

    /**
     * handleInputChange
     *      called to handle editing changes performed by user in form
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    /**
     * handleDefaults
     *      called to handle defaulting logic on the form
     */
    handleDefaults(event) {
    }

    /**
     * handleAppPost
     *      called to perform the REST API POST operation to instantiate new Eigengrau application via callback routine
     */
    handleAppPost(event) {
        const registrationKey = this.state.registrationKey;
        const owner = this.state.owner;
        const label = this.state.label;

        this.props.postApplication(
            (result) => {
                this.setState({message: 'Application POST result: ' + JSON.stringify(result)});
            },
            (error) => {
                this.setState({message: 'Application POST Error: ' + JSON.stringify(error)});
            },
            registrationKey,
            owner,
            label,
        );

        event.preventDefault();
    }

    /**
     * render
     *      routine called by react JS to layout UI controls
     */
    render() {
        const { error, isLoaded } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
                const message = this.state.message;

                return(
                    <React.StrictMode>
                    <SLTHeader props logo={this.props.logo} rightmenuitem={{label:'Sign In',link:'/'}} />
                    <form onSubmit={this.handleAppPost}>
                        <table className="Page_Table" name="appPostTable" align="center">
                            <tbody>
                            <tr>
                                <td>
                                    <FormMessage message={message}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="Page_Col">
                                    <input className="Edit_Ctl" type="Text" name="registrationKey" id="registrationKeyFieldId" placeholder="Registration Key" maxLength="255" onChange={this.handleChange} onBlur={this.handleDefaults} />
                                </td>
                            </tr>
                            <tr>
                                <td className="Page_Col">
                                    <input className="Edit_Ctl" type="Text" name="owner" id="ownerFieldId" placeholder="Owner" maxLength="255" onChange={this.handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="Page_Col">
                                    <input className="Edit_Ctl" type="Text" name="label" id="labelFieldId" placeholder="Label" maxLength="255" onChange={this.handleChange} onBlur={this.handleDefaults} />
                                </td>
                            </tr>
                            <tr>
                                <td className="Page_Col">                    
                                    <input className="Submit_Ctl" type="submit" id="appPostButton" value="Post" />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                    </React.StrictMode>
                );    
        }
    }
}  

export default AppPost;