/**
 * module: LogoutForm.js
 * author: Rob Blanch
 * usage: logic for Eigengrau Admin logout of session
 * license: see https://www.stonylanetech.com/licenses/Eigengrau/license.txt for details
 * 
 * (C) 2024 Stony Lane Tech LLC
 */
import React from 'react';
import SLTHeaderNavBar from './SLTHeaderNavBar.js';
import FormMessage from './FormMessage.js';

/**
 * class: LogoutForm
 * usage: used to logout of session and present UI to user
 * props:
 *      logoItem - logo information to display in nav header of form
 *      isNavBarExpanded - true/false flag indicating if nav bar menu should be expanded
 *      setNavBarExpanded(newExpandState) - callback to alter state of isNavBarExpanaded to newExpandState value
 *      deleteSession(onComplete, onError) - routine called to remove session from service
 */
class LogoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            logoutAttempted: false,
        };
    }

    /**
     * componentDidMount
     *      called by react js when UI has components mounted
     */
    componentDidMount()
    {
        if (this.state.logoutAttempted === false) {
            this.setState({logoutAttempted: true});
            
            this.props.deleteSession(
                (result) => {
                    this.setState({message: 'User session has ended.'});
                },
                (error) => {
                    this.setState({message: JSON.stringify(error)});
                },
            );
        }
    }

    /**
     * render
     *      react js called routine to layout UI
     */
    render() {
        const message = this.state.message;
        const navItems = [
            {link: '/login',label:'Login',icon:null}
        ];

        return(
            <React.StrictMode>
            <SLTHeaderNavBar    props
                                logoItem={this.props.logoItem}
                                navItems={navItems}
                                isNavBarExpanded={this.props.isNavBarExpanded}
                                setNavBarExpanded={this.props.setNavBarExpanded} />
            <table className="Page_Table">
                <tbody>
                    <tr>
                        <td>
                            <FormMessage message={message} />
                        </td>
                    </tr>
                </tbody>
            </table>
            </React.StrictMode>
        );
    }
}  

export default LogoutForm;