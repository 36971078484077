/**
 * module: SLTHeader.js
 * author: Rob Blanch
 * created: 6/24/2019
 * usage: Header UI component of Stony Lane Tech Single Page App
 * license: see https://www.stonylanetech.com/licenses/license.txt for details
 * 
 * (C) 2019 Stony Lane Tech LLC
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/**
 * SLTHeader
 *   present a consistent application header in SLT Single Page App UI
 * 
 *   props include:
 *    logo : PNG logo to display in middle of header bar for application
 *    leftmenuitem : left menu item to display in header bar (contains label, link, icon properties)
 *                  if label & link are null then no menu item will be displayed
 *    rightmenuitem : right menu item to display in header bar (contains label, link, icon properties)
 *                  if label & link are null then no menu item will be displayed
 * 
 * CSS style guide (class names):
 *  SLT-Header: container for header rendered
 *  SLT-Top-Left-Menu-Bar: table containing single left menu item row & column
 *  SLT-Top-Left-Menu-Bar-Item: td containing single left menu item in SLT-Top-Menu-Bar table
 *  SLT-Top-Left-Menu-Bar-Icon: icon for single left menu item in SLT-Top-Menu-Bar
 *  SLT-App-Logo: logo in middle of header, image to format properly in CSS style
 *  SLT-Top-Right-Menu-Bar: table containing single right menu item row & column
 *  SLT-Top-Right-Menu-Bar-Item: td containing single right menu item in SLT-Top-Menu-Bar table
 *  SLT-Top-Right-Menu-Bar-Icon: icon for single right menu item in SLT-Top-Menu-Bar
 */
class SLTHeader extends Component {
    /**
     * renderTopLeftMenuItem
     *  renders JSX for the top left menu item on display in header
     */
    renderTopLeftMenuItem() {
        var label = (this.props.leftmenuitem) ? this.props.leftmenuitem.label : '';
        var onclick = (this.props.leftmenuitem) ? this.props.leftmenuitem.onclick : null;
        var link = (this.props.leftmenuitem) ? this.props.leftmenuitem.link : null;
        var icon = (this.props.leftmenuitem && this.props.leftmenuitem.icon) ? this.props.leftmenuitem.icon : null;

        if (label && onclick) {
            return (
                <table className="SLT-Top-Left-Menu-Bar" colSpan="1">
                    <tbody>
                        <tr key={label}>
                            <td className="SLT-Top-Left-Menu-Bar-Item">
                                <button className="SLT-Top-Left-Menu-Bar-Button" onClick={onclick} name={label} id={"id_"+label} >
                                    {label}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        } else if (label && link) {
            return (
                <table className="SLT-Top-Left-Menu-Bar" colSpan="1">
                    <tbody>
                        <tr key={label}>
                            <td className="SLT-Top-Left-Menu-Bar-Item">
                                <Link to={link.toString()}>
                                    {(icon) ?
                                        <img className="SLT-Top-Left-Menu-Bar-Icon" src={icon.default} alt={label.toString()} /> :
                                        label.toString()}
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            );    
        } else {
            return null;
        }
    }

    /**
     * renderLogo
     *  returns the jsx for showing the logo
     */
    renderLogo() {
        return (
            <div className="SLT-App-Logo" id="SLTHeaderLogo" >
                <img className="SLT-App-Logo" src={this.props.logo} alt="logo" />
            </div>
        );
    }

    /**
     * renderTopRightMenuItem
     *  renders JSX for the top right menu item on display in header
     */
    renderTopRightMenuItem() {
        var label = (this.props.rightmenuitem) ? this.props.rightmenuitem.label : '';
        var onclick = (this.props.rightmenuitem) ? this.props.rightmenuitem.onclick : null;
        var link = (this.props.rightmenuitem) ? this.props.rightmenuitem.link : null;
        var icon = (this.props.rightmenuitem && this.props.rightmenuitem.icon) ? this.props.rightmenuitem.icon : null;

        if (label && onclick) {
            return (
                <table className="SLT-Top-Right-Menu-Bar" colSpan="1">
                    <tbody>
                        <tr key={label}>
                            <td className="SLT-Top-Right-Menu-Bar-Item">
                                <button className="SLT-Top-Right-Menu-Bar-Button" onClick={onclick} name={label} id={"id_"+label} >
                                    {label}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        } else if (label && link) {
            return (
                <table className="SLT-Top-Right-Menu-Bar" colSpan="1">
                    <tbody>
                        <tr key={label}>
                            <td className="SLT-Top-Right-Menu-Bar-Item">
                                <Link to={link.toString()}>
                                    {(icon) ?
                                        <img className="SLT-Top-Right-Menu-Bar-Icon" src={icon.default} alt={label.toString()} /> :
                                        label.toString()}
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            );    
        } else {
            return null;
        }
    }

    /**
     * header : Renders header jsx
     */
    header() {
        return (
            <header className="SLT-Header">
                {this.renderTopLeftMenuItem()}
                {this.renderLogo()}
                {this.renderTopRightMenuItem()}
            </header>
        );
    }

    /**
     * render
     *  react JS main rendering function called to provide UI information for control
     */
    render() {
        return (this.header());
    }
}

export default SLTHeader;