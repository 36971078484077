/**
 * module: SLTFooter.js
 * author: Rob Blanch
 * created: 6/24/2019
 * usage: footer component Stony Lane Tech application
 * license: see https://www.stonylanetech.com/licenses/license.txt for details
 * 
 * (C) 2019 Stony Lane Tech LLC
 */
import React, { Component } from 'react';

/**
 * SLTFooter
 *   present a consistent application footer in SLT Single Page App UI
 * 
 *   props include:
 *    copyright : text to display for copyright link in footer
 *    menuitem : right menu item to display in header bar (contains label, text, icon properties)
 *                  if label & text are null then no menu item will be displayed
 * 
 * CSS style guide (class names):
 *  SLT-Footer: container for footer rendered
 */
class SLTFooter extends Component {
    /**
     * render
     *  render the JSX for react js UI of footer for Stony Lane Tech Single Page UI App
     */
    render() {
        return (
            <footer className="SLT-Footer">
                <address><a href="mailto:support@stonylanetech.com" style={{"textDecoration": "none"}}>Copyright &copy;{this.props.copyright}. All rights reserved.</a></address>
            </footer>
        );
    }
}

export default SLTFooter;