/**
 * module: SLTRtrQryParamHelper.js
 * author: Rob Blanch
 * usage: utility to process parameters from query parameters in call to the form
 * license: see https://www.stonylanetech.com/licenses/Eigengrau/license.txt for details
 * 
 * (C) 2020 Stony Lane Tech LLC
 */
const SLTEigengrauAppErrCodes = require('./SLTEigengrauAppErrCodes.js').SLTEigengrauAppErrCodes;
const SLTEigengrauAppErrHelper = require('./SLTEigengrauAppErrHelper.js').default;

/**
 * class: SLTRtrQryParamHelper
 * usage: used to help with standard parsing and checking of params on query string into react router calls
 */
class SLTRtrQryParamHelper {
    /**
     * getParams
     *      used to get params structure from props.location.search query string. validates set of "must" params
     * and will throw standard error if not found.
     */
    getParams(props, apiName = null, mustParams = null, paramMissingErrCode = null) {
        /**
         * parse the parameters from query string into params struct to return to caller for usage
         */
        const queryString = require('query-string');
        const params = queryString.parse(props.location.search);
        const SLTEigengrauAppErr = new SLTEigengrauAppErrHelper();

        if (mustParams) {
            var missingParam = null;
            var fThrowError = false;

            /**
             * check must params exist in query string
             */
            for (var idx = 0; idx < mustParams.length; idx++) {
                if (params) {
                    var doesParamExist = (params[mustParams[idx]]) ? true : false;
                    if (doesParamExist === false) {
                        missingParam = mustParams[idx];
                        fThrowError = true;
                        break;
                    }
                } else {
                    missingParam = "all";
                    fThrowError = true;
                    break;
                }
            }

            /**
             * handle throwing missing parameter error
             */
            if (fThrowError) {
                var errcode = (paramMissingErrCode) ? paramMissingErrCode : SLTEigengrauAppErrCodes.APPERR_MISSINGPARAM;

                SLTEigengrauAppErr.throwAppErr(errcode, "APPERR_MISSINGPARAM [" + SLTEigengrauAppErrCodes.APPERR_MISSINGPARAM + "]: Missing expected parameter in sltEigengrau url request", {misingParam : missingParam});
            }
        }

        /**
         * return the parameters to caller
         */
        return params;
    }  
}

export default SLTRtrQryParamHelper;