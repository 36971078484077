/**
 * module: SLTEditControl.js
 * author: Rob Blanch
 * usage: rendering logic for Edit Control (can be readOnly)
 * license: see https://www.stonylanetech.com/licenses/license.txt for details
 * 
 * (C) 2020 Stony Lane Tech LLC
 */
import React from 'react';

/**
 * class: SLTEditControl
 * usage: rendering logic for the standard Edit Control in SLT react js applications
 *      note: it expects to render a row within a parent HTML table (ie. <tr></tr> is returned)
 * props:
 *      value - (mandatory) binding to value to display or edit in control
 *      name - (mandatory) name of control in html if you want different than key which is default
 *      onChange - (optional) if supplied control is editable, and change occurs will call this routine upon change being made
 *      onBlur - (optional) if supplied control is editable, and when change and leaving control it will be routine called
 *      maxlength - (optional) if editable, max length of data to be entered
 *      label - (optional) label to apply to control (if not supplied no label displayed)
 *      placeholder - (optional) text to show if no value in control
 */
class SLTEditControl extends React.Component {
    /**
     * constructor
     */
    constructor(props) {
        super(props);

        var isMissingProps = true;

        /**
         * check to make sure mandatory props have been supplied
         */
        if (props.name !== undefined && props.name !== null && props.name.length > 0 &&
            props.value !== undefined && props.value !== null) {
            isMissingProps = false;
        }

        /**
         * set the initial state
         */
        this.state = {
            isMissingProps: isMissingProps,
        };
    }

    /**
     * isPasswordField
     *      return true if supplied name of field matches list of password related
     * names, and therefore create a password protected field instead of regular edit field
     */
    isPasswordField(name) {
        var lname = name.toLowerCase();
        return ((lname.localeCompare("password") === 0) ||
                (lname.localeCompare("pwd") === 0)) ? true : false;
    }

    /**
     * render
     *      react js called routine to render the control
     */
    render() {
        if (this.state.isMissingProps === false) {
            var name = this.props.name;
            var value = this.props.value;
            var onChange = (this.props.onChange) ? this.props.onChange : null;
            var onBlur = (this.props.onBlur) ? this.props.onBlur : null;
            var maxlength = (this.props.maxlength) ? this.props.maxlength : 30;
            var label = (this.props.label) ? this.props.label : null;
            var placeholder = (this.props.placeholder) ? this.props.placeholder : null;
            var id = name + "Id";
            var readOnly = (onChange || onBlur) ? false : true;
            var type = (this.isPasswordField(name)) ? "password" : "text";
    
            return (
                <tr className="Page_Row_Label">
                    <td className="Page_Col">
                        <label htmlFor={id}>{label}
                            <input className="Edit_Ctl" type={type} name={name} id={id} placeholder={placeholder} maxLength={maxlength} value={value} onChange={onChange} onBlur={onBlur} readOnly={readOnly}/>
                        </label>
                    </td>
                </tr>    
            );    
        } else {
            return(
                <tr className="Page_Row_Label">
                    <td className="Page_Col">
                        <p>SLTEditControl missing mandatory props. Check rendered SLTEditControl.</p>
                    </td>
                </tr>
            );
        }
    }
}

export default SLTEditControl;
