/**
 * module: Version.js
 * author: Rob Blanch
 * usage: rendering logic for showing version information for the Eigengrau react js application
 * license: see https://www.stonylanetech.com/licenses/Eigengrau/license.txt for details
 * 
 * (C) 2024 Stony Lane Tech LLC
 */
import React from 'react';
import SLTHeaderNavBar from './SLTHeaderNavBar.js';
import SLTEditControl from './SLTEditControl.js';
import HomeMessage from './FormMessage.js';

/**
 * class: Version
 * usage: renders version information
 * props:
 *      logoItem - logo information to display in nav header of form
 *      isNavBarExpanded - true/false flag indicating if nav bar menu should be expanded
 *      setNavBarExpanded(newExpandState) - callback to alter state of isNavBarExpanaded to newExpandState value
 *      getVersion(onSuccess, onError) - callback routine to call service API to obtain version information to display
 */
class Version extends React.Component {
    constructor(props) {
        super(props);
        var isMissingProps = true;

        if (this.props !== undefined && this.props !== null &&
            this.props.logoItem !== undefined && this.props.logoItem !== null &&
            this.props.isNavBarExpanded !== undefined && this.props.isNavBarExpanded !== null &&
            this.props.setNavBarExpanded !== undefined && this.props.setNavBarExpanded !== null &&
            this.props.getVersion !== undefined && this.props.getVersion !== null) {
                isMissingProps = false;
        }

        this.state = {
            isMissingProps: isMissingProps,
            error: null,
            isLoaded: false,
            version: '',
            service_version: '',
        };
    }

    /**
     * handleSubmit
     *  handle a submit event to perform a task related to user action on the form
     */
    handleSubmit(event) {
        event.preventDefault();
    }

    /**
     * componentDidMount
     *      react js calls routine once UI components are loaded
     */
    componentDidMount() {
        this.props.getVersion(            
            (versioninfo) => {            
                this.setState({            
                    isLoaded:true,            
                    version: (versioninfo.version !== undefined) ? versioninfo.version : 'no info',
                    service_version: (versioninfo.serviceversion !== undefined) ? versioninfo.serviceversion : 'no info',
                    auth_version: (versioninfo.authversion !== undefined) ? versioninfo.authversion : 'no info',
                });            
            },            
            (error) => {
                this.setState({
                    isLoaded: true,
                    error: error
                });
            }
        );
    }

    /**
     * render
     *      react js called routine to layout UI
     */
    render() {
        const { error, isLoaded, version, service_version, auth_version } = this.state;
        const navItems = [
            {link:'/login',label:'Login',icon:null}
        ];

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return(
                <React.StrictMode>
                <SLTHeaderNavBar    props
                                logoItem={this.props.logoItem}
                                navItems={navItems}
                                isNavBarExpanded={this.props.isNavBarExpanded}
                                setNavBarExpanded={this.props.setNavBarExpanded} />
                <HomeMessage props error={this.state.error} message={(this.state.error) ? this.state.error.message : null} />

                <form onSubmit={this.handleSubmit} style={{"paddingTop":"75px"}}>
                    <div className="ProfileHeader">
                        <div className="ProfileHeaderLabel">
                            Version Information
                        </div>
                    </div>

                    <table className="Page_Table" name="VersionTable" id="VersionTableId">
                        <tbody>
                            <SLTEditControl props
                                            name="site"
                                            key="site"
                                            value={version}
                                            label="Interface: " />
                            <SLTEditControl props
                                            name="service"
                                            key="service"
                                            value={service_version}
                                            label="API: " />
                            <SLTEditControl props
                                            name="auth_service"
                                            key="auth_service"
                                            value={auth_version}
                                            label="Auth: " />
                        </tbody>
                    </table>
                </form>
                </React.StrictMode>
            );    
        }
    }
}  

export default Version;