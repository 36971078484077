/**
 ** module: SLTHomeEigengrau.js
 ** usage: Eigengrau Home Screen UI
 ** created: 04/24/2024
 ** owner: Rob Blanch
 **/
 import React from 'react';
 import {withRouter} from 'react-router-dom';
 import SLTHeaderNavBar from './SLTHeaderNavBar.js';
 import SLTInformationTablet from './SLTInformationTablet.js';
 import FormMessage from './FormMessage.js';
 
 /**
  * class: SLTHomeEigengrau
  * usage: used to provide "home" page or landing page for Eigengrau (ie. Stony Lane Tech LLC main website)
  * props:
  *     logoItem: information about the logo to display on header nav bar
  *     navItems: array of nav menu items to display in the header of the page with navigation links should user select
  *     isNavBarExpanded: true if nav bar menu expanded and false if not
  *     setNavBarExpanded(newExpandState): callback when to set new state for nav bar exanded state
  *     websitename: (optional) website name to display in header
  *     backgroundcolor: color of background
  */
 class SLTHomeEigengrau extends React.Component {
    /**
     * constructor
     *  props: react js properties to utilize
     */
     constructor(props) {
        var isMissingProps = true;

        super(props);

        if (this.props !== undefined && this.props !== null &&
             this.props.logoItem !== undefined && this.props.logoItem !== null &&
             this.props.navItems !== undefined && this.props.navItems !== null &&
             this.props.isNavBarExpanded !== undefined && this.props.isNavBarExpanded !== null &&
             this.props.setNavBarExpanded !== undefined && this.props.setNavBarExpanded !== null) {
                isMissingProps = false;
        }

        this.state = {
             isMissingProps: isMissingProps,
             message: null,
             error: null,
        }; 
     }

     /**
      * smartRenderInfoTablet
      *     used to render the info tablet information only if the nav bar menu is not expanded
      * in order to avoid conflict between controls within menu and info tablet.
      * 
      * isNavBarExpanded - true/false flag indicating if nav bar is expanded or not.
      */
     smartRenderInfoTablet(isNavBarExpanded) {
        if (isNavBarExpanded === false) {
            const sltTitle = "Stony Lane Tech";
            const sltSlogan = "Tech built to survive everday challenges";
            const sltInfoBase = "For millennia the stony lane remains one of the most sustainable technologies utilized throughout the world to withstand rigors of multi-modal transit across a wide array of topological and environmental conditions.\n\nAt Stony Lane Tech we aim to produce resiliant and valuable technological solutions for the modern world. Stony Lane Tech utilizes a combination of hardware, software, machine learning (ML) and artifical intelligence (AI) to build solutions as durable as a stony lane."
/*            const sltBulletList = {
               "listStyle": "circle",
               "listPosition": "inside",
               "listBackground": "#FFFFFF",
               "listItemColor": "#000000",
               "bullets": [
                   {"text": "line item 1", "background": "#FFFFFF", "color": "#000000"},
                   {"text": "line item 2", "background": "#FFFFFF", "color": "#000000"}
               ]
            }; */

            return(<SLTInformationTablet   title={sltTitle}
                                    slogan={sltSlogan}
                                    information={sltInfoBase}
                                    bulletList={null} />);
        }

        return null;
     }

     /**
      * render
      *      routine called by react js to layout UI
      */
     render() {
         const error = this.state.error;
         const message = this.state.message;

        if (this.state.isMissingProps === false) {
            return(
                <React.StrictMode>
                    <SLTHeaderNavBar logoItem={this.props.logoItem}
                                    navItems={this.props.navItems}
                                    isNavBarExpanded={this.props.isNavBarExpanded}
                                    setNavBarExpanded={this.props.setNavBarExpanded}
                                    websitename={(this.props.websitename) ? this.props.websitename : null}
                                    backgroundcolor={(this.props.backgroundcolor) ? this.props.backgroundcolor : "#FFFFFF"} />
                    {this.smartRenderInfoTablet((this.props.isNavBarExpanded) ? true : false)}
                    <FormMessage error={error} message={message} />
                </React.StrictMode>
            );   
        } else {
            return(
                <FormMessage message={"SLTHomeEigengrau missing mandatory props. Check rendered SLTHomeEigengrau."}/>
            );
        }
     }
 }  
 
 export default withRouter(SLTHomeEigengrau);