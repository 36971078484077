/**
 ** module: SLTInformationTablet.js
 ** usage: UI to display information to the user as a tablet looking window
 ** created: 05/02/2024
 ** owner: Rob Blanch
 **/
 import React from 'react';
 import {withRouter} from 'react-router-dom';
 import './SLTInformationTablet.css';
 import FormMessage from './FormMessage.js';
 
 /**
  * class: SLTInformationTablet
  * usage: used to provide information to the user via a tablet looking UI
  * props:
  *     slogan: (optional)used to place on overarching slogan at top of tablet
  *     information: (mandatory)string of information to display to the user
  *     bulletList: (optional)list of bullets to display in list
  */
 class SLTInformationTablet extends React.Component {
    /**
     * constructor
     *  props: react js properties to utilize
     */
     constructor(props) {
        var isMissingProps = true;

        super(props);

        if (this.props !== undefined && this.props !== null &&
             this.props.information !== undefined && this.props.information !== null) {
                isMissingProps = false;
        }

        this.state = {
             isMissingProps: isMissingProps,
             message: null,
             error: null,
        }; 
     }

    /**
     * renderSloganControl
     *  used to obtain the html to render the control containing the slogan as supplied via props
     */
    renderSloganControl() {
        if (this.props.slogan !== undefined && this.props.slogan !== null) {
            return(
                <p className="slt_info_slogan">
                    {this.props.slogan}
                </p>
            );
        }

        return null;
    }

    /**
     * renderInformationControl
     *  used to obtain the html to render
     */
    renderInformationControl() {
        if (this.state.isMissingProps === false) {
            return(
                <p className="slt_info_text">
                    {this.props.information}
                </p>
            );
        }

        return null;
    }

    /**
     * renderBullet
     *      routine to render the html for a single bullet to contain in bullet list.
     *   bulletEntry: bullet details which includes:
     *          bulletKey: (optional) unique key for bullet entry in list. default will be "slt_bullet_XX" where XX is index of bullet in list.
     *          text: (mandatory) text to display for bullet in list.
     *   idxBullet: (mandatory) index of bullet in the list.
     */
    renderBullet(bulletEntry, idxBullet) {
        if (bulletEntry !== undefined && bulletEntry !== null && "text" in bulletEntry) {
            var bulletKey = ("bulletKey" in bulletEntry) ? bulletEntry.bulletKey : ("slt_bullet_" + idxBullet);

            return(
                <li className="slt_bullet_point" key={bulletKey}>{bulletEntry.text}</li>
            );
        }

        return null;
    }

    /**
     * renderBulletList
     *      routine called to render a bullet list of bullet items
     * listToDisplay: structure to specify look and feel of bullet list to display includes:
     *      listKey: (optional) specifies the key for the outer list element. "slt_bullet_list" by default.
     *      bullets: (mandatory) list of items to display in list where:
     *          bulletKey: (optional) unique key to use for item in list. default is "slt_bullet_XX" where XX is index of item in list.
     *          text: (mandatory) text to display for bullet line
     */
    renderBulletList(listToDisplay) {
        if (listToDisplay !== undefined && listToDisplay !== null) {
            var bullets = [];
            var listKey = ("listKey" in listToDisplay) ? listToDisplay.listKey : "slt_bullet_list";

            for (var idxBullet=0; idxBullet < listToDisplay.bullets.length; idxBullet++) {
                var bulletEntry = this.renderBullet(listToDisplay.bullets[idxBullet], 
                                                    idxBullet);
                if (null !== bulletEntry) {
                    bullets.push(bulletEntry);
                }
            }

            if (bullets.length > 0) {
                return(
                    <ul className="slt_bullet_list" key={listKey}>
                        {bullets}
                    </ul>
                );    
            }
        }

        return null;
    }

    /**
      * render
      *      routine called by react js to layout UI
      */
    render() {
        const error = this.state.error;
        const message = this.state.message;

        if (this.state.isMissingProps === false) {
            var bulletList = null;
            if (this.props.bulletList !== undefined && this.props.bulletList !== null) {
                bulletList = this.renderBulletList(this.props.bulletList);
            }

            return(
                <React.StrictMode>
                    <div className="slt_info_tablet">
                        <div className="slt_tablet_inlay">
                            {this.renderSloganControl()}
                            {this.renderInformationControl()}
                            {bulletList}
                        </div>
                    </div>
                    <FormMessage error={error} message={message} />
                </React.StrictMode>
            );   
        } else {
            return(
                <FormMessage message={"SLTInformationTablet missing mandatory props. Check rendered SLTInformationTablet."}/>
            );
        }
    }
}  
 
export default withRouter(SLTInformationTablet);